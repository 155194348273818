import { StrictMode } from "react";
import { render } from "react-dom";
import { AppStateProvider } from "./components/AppStateProvider/AppStateProvider";
import { ThemeProvider } from "./components/ThemeProvider/ThemeProvider";
import { ReportWebVitals } from "./core/ReportWebVitals";
import { Router } from "./core/Router";

render(
	<StrictMode>
		<ThemeProvider>
			<AppStateProvider>
				<Router />
			</AppStateProvider>
		</ThemeProvider>
	</StrictMode>,
	document.querySelector("#root")
);

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
// eslint-disable-next-line no-console
ReportWebVitals(console.log);
