import { useContext } from "react";
import { NFCCContext } from "../../components/NFCCProvider/NFCCProvider";
import { NFCCContextType } from "../../types/ciptex-sdk";

export const useNFCCContext = (): NFCCContextType => {
	const context = useContext(NFCCContext);
	if (!context) {
		throw new Error("useNFCCContext must be used within a CCPProvider");
	}
	return context;
}