/* eslint @typescript-eslint/no-var-requires: "off" */

import { FC, useState, FormEvent, useEffect, ChangeEvent } from "react";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Box } from "@twilio-paste/core/box";
import { Spinner } from "@twilio-paste/core/spinner";
import { Stack } from "@twilio-paste/core/stack";
import { Label } from "@twilio-paste/core/label";
import { HelpText } from "@twilio-paste/core/help-text";
import { Heading } from "@twilio-paste/core/heading";
import { Button } from "@twilio-paste/core/button";
import { Alert } from "@twilio-paste/core/alert";
import { Text } from "@twilio-paste/core/text";
import { Token } from "@ciptex/nfcc";
import { useHeight } from "../../hooks/useHeight/useHeight";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { useNavigate, useSearchParams, Link, generatePath } from "react-router-dom";
import { LOGO, Role } from "../../constants";
import { Anchor } from "@twilio-paste/core";
import { ShowIcon } from "@twilio-paste/icons/esm/ShowIcon";
import { HideIcon } from "@twilio-paste/icons/esm/HideIcon";



export const SSOPage: FC = () => {

	const height = useHeight();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { signIn, token, appDispatch, appState } = useAppState();
	const { connect: ccpConnect, providerReady } = useNFCCContext();

	const login = async () => {

		try {
			await signIn( "", "", JSON.parse(decodeURI(searchParams.get("Token") ?? '')) as Token);
			//setIsLoading(false);
		} catch (error) {
			//setIsLoading(false);
			//setAlert(true);
		}
	};


	useEffect(() => {
		if(searchParams.get("IsAuthd") === 'True')
		{
			login()
		}	
	}, []);


	useEffect(() => {
		if (providerReady && appState.token && appState.token !== "" && appState.token !== null) {
			if (appState.role === Role.admin) {
				navigate(searchParams.get("redirect") || "/agencies", { replace: true });
			} else if (appState.role === Role.client) {
				navigate(searchParams.get("redirect") || "/home", { replace: true });
			}
		}
	}, [providerReady, appState.token]);

	useEffect(() => {
		if (token) {
			appDispatch({ type: "set-token", token: token.token ? token.token : "" });
			appDispatch({ type: "set-role", role: token.role ? token.role : "" });
			appDispatch({ type: "set-email", email: token.email ? token.email : "" });
			appDispatch({ type: "set-agencyId", agencyId: token.agencyId ?? 0 });
			appDispatch({ type: "set-agencyName", agencyName: token.agencyName ?? "" });
			ccpConnect(token.token ? token.token : "");
		}
	}, [token]);





	return (
		<Box height={height} display="flex" flexDirection="column" flex={1} alignContent="center" justifyContent="center" alignItems="center" justifyItems="center">
			<Box borderColor="colorBorder" borderWidth="borderWidth10" borderStyle="solid" width={["100%", "30rem", "30rem"]} borderRadius="borderRadius30">


					<Box display="flex" width="fit-content" alignItems="center" style={{ background: "#067fa7" }} justifyContent="center" paddingTop="space60" paddingBottom="space60">
						<img width="50%" src={LOGO}></img>
					</Box>
					<Box padding="space100" alignItems="center" justifyContent="center" display="flex">
						<Stack orientation="vertical" spacing="space80">
						
						{(searchParams.get("ErrorReason") ?? '').length > 0 && 'Error logging in. Details: ' + searchParams.get("ErrorReason")}
							</Stack>
						


					</Box>
			</Box>
		</Box>

	);
};

