import { FC } from "react";
import { Box } from "@twilio-paste/box";
import { HEADERS_HEIGHT } from "../../constants";
import { SidenavLinks } from "./SidenavLinks";

export const SideNav: FC = () => {
	return (
		<Box backgroundColor="colorBackground" display={["none", "none", "flex"]} flexDirection="column" height={`calc(100vh - ${HEADERS_HEIGHT})`}
			minWidth="sizeSidebar"
			position="sticky"
			top={HEADERS_HEIGHT}
			zIndex="zIndex10">
			<Box marginTop="space70" marginX="space20" overflow="auto" role="navigation" aria-label="Main Nav" display="flex" justifyContent="center"
				paddingY="space40"
				paddingX="space60">
				<Box display="flex" alignItems="flex-start" width="100%" flexDirection="column" zIndex="zIndex90" rowGap="space70">
					<SidenavLinks />
				</Box>
			</Box>
		</Box>
	);
};
