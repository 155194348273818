import { FC, useEffect, useState } from "react";
import { ArrowUpIcon } from "@twilio-paste/icons/esm/ArrowUpIcon";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import styled, { keyframes } from "styled-components";

export const Scroller: FC = () => {
	const [showScroll, setShowScroll] = useState(false);

	const checkScrollTop = () => {
		if (!showScroll && window.scrollY > 300) {
			setShowScroll(true);
		} else if (showScroll && window.scrollY < 100) {
			setShowScroll(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", checkScrollTop);
	}, []);

	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const fadeIn = keyframes`
		0% {
			opacity: 0;
		}
		50% {
			opacity: 0.5;
		}
		100% {
			opacity: 1;
		}
	`;

	const Animate = styled.div`
    animation: ${fadeIn} 0.6s ease-in-out;
    display: flex;
    justify-content: flex-end;
    top: 90%;
    position: sticky;
    z-index: 2;
  `;

	return (
		<>
			{showScroll && (
				<Animate>
					<Box
						width="50px"
						display="flex"
						justifyContent="center"
						borderRadius="borderRadiusCircle"
						backgroundColor="colorBackgroundBodyInverse"
						_hover={{ backgroundColor: "colorBackgroundPrimary" }}
					>
						<Button variant="link" onClick={scrollTop}>
							<ArrowUpIcon
								color="colorTextIconBrandInverse"
								size="sizeIcon100"
								className="scrollTop"
								style={{ height: 40, display: showScroll ? "flex" : "none" }}
								decorative={false}
								title="return to top button"
							/>
						</Button>
					</Box>
				</Animate>
			)}
		</>
	);
};
