/* eslint @typescript-eslint/no-var-requires: "off" */

import { ChangeEvent, FC, useEffect, useState } from "react";
import { Box } from "@twilio-paste/core/box";
import { Select, Option } from "@twilio-paste/core/select";
import { Label } from "@twilio-paste/core/label";
import { Text } from "@twilio-paste/core/text";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { Button } from "@twilio-paste/core/button";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { Stack } from "@twilio-paste/core/stack";
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalFooterActions,
	ModalHeader,
	ModalHeading
} from "@twilio-paste/core/modal";
import { useUID } from "@twilio-paste/core/dist/uid-library";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Input } from "@twilio-paste/core/input";
import { Heading } from "@twilio-paste/core/heading";
import { Checkbox, CheckboxGroup } from "@twilio-paste/core/checkbox";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Holidays, Holiday, HolidayNew } from "@ciptex/nfcc";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@twilio-paste/core/date-picker";
import { TimePicker } from "@twilio-paste/core/time-picker";
import { DateTime } from "luxon";
import { Alert, HelpText, Paragraph } from "@twilio-paste/core";

export const HolidaysPage: FC = () => {
	const { toaster } = useToasterContext();
	const navigate = useNavigate();
	const {
		getHolidays,
		getAgency,
		listTimezones,
		updateAgency,
		createHoliday,
		updateHoliday,
		deleteHoliday
	} = useNFCCContext();
	const { appState } = useAppState();
	const [holidays, setHolidays] = useState<any[]>([]);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [tz, setTz] = useState<string>();
	const [deleteIndex, setDeleteIndex] = useState<string>("");
	const [editIndex, setEditIndex] = useState<number>(0);
	const [editingHoliday, setEditingHoliday] = useState<any>();
	const [pageError, setPageError] = useState<boolean>(false);
	const [nameError, setNameError] = useState<string>("");
	const [startDateError, setStartDateError] = useState<string>("");
	const [channelError, setChannelError] = useState<string>("");

	const [editIsOpen, setEditIsOpen] = useState(false);

	const { agencyId } = useParams();

	{
		/* Add holiday modal states */
	}
	const handleAddOpen = () => {
		const newHoliday: any = {
			index: -Math.floor(Math.random() * 1000 + 1),
			startDate: new Date().toISOString().substring(0, 16),
			endDate: new Date().toISOString().substring(0, 16),
			name: "",
			notes: "",
			channels: []
		};
		setEditingHoliday(newHoliday);
		setEditIndex(newHoliday.index);
		setEditIsOpen(true);
	};

	{
		/* Edit holiday modal states */
	}

	const handleEditOpen = (index: number) => {
		setEditIndex(index);
		if (holidays) {
			setEditingHoliday(holidays.filter((i) => i.index === index)[0]);
			setEditIsOpen(true);
		}
	};
	const handleEditClose = () =>{
		setEditIsOpen(false);
		setNameError("");
		setStartDateError("");
		setPageError(false);
		setChannelError("");
	}
	const editModalHeadingID = useUID();

	{
		/* Delete holiday modal states */
	}
	const [deleteIsOpen, setDeleteIsOpen] = useState(false);
	const handleDeleteOpen = (index: string) => {
		setDeleteIsOpen(true);
		setDeleteIndex(index);
	};
	const handleDeleteClose = () => setDeleteIsOpen(false);
	const deleteModalHeadingID = useUID();

	const tzs = [
		{ name: "EST", tag: "America/New_York" },
		{ name: "EDT", tag: "America/New_York" },
		{ name: "CST", tag: "America/Chicago" },
		{ name: "CDT", tag: "America/Chicago" },
		{ name: "MST", tag: "America/Denver" },
		{ name: "MDT", tag: "America/Denver" },
		{ name: "PST", tag: "America/Los_Angeles" },
		{ name: "PDT", tag: "America/Los_Angeles" },
		{ name: "HST", tag: "Pacific/Honolulu" },
		{ name: "HSDT", tag: "Pacific/Honolulu" },
		{ name: "AKST", tag: "America/Anchorage" },
		{ name: "AKDT", tag: "America/Anchorage" },
		{ name: "AST", tag: "America/Halifax" },
		{ name: "ADT", tag: "America/Halifax" },
		{ name: "EGST", tag: "Europe/London" },
		{ name: "EGT", tag: "Europe/London" },
		{ name: "GMT", tag: "Europe/London" },
		{ name: "NST", tag: "America/St_Johns" },
		{ name: "NSDT", tag: "America/St_Johns" },
		{ name: "PMDT", tag: "America/Miquelon" },
		{ name: "PMST", tag: "America/Miquelon" },
		{ name: "WGST", tag: "America/Godthab" },
		{ name: "WGT", tag: "America/Godthab" }
	];

	useEffect(() => {
		(async () => {
			try {
				const agId = parseInt(agencyId ?? "0") || appState.agencyId;
				const hols: Holidays = await getHolidays(agId);

				const agency = await getAgency(agId);

				setTz(agency.timezone);
				const offset = agency.utcOffset;

				const onlineHols = hols.filter((x) => x.connectionType === "Online");
				const phoneHols = hols.filter((x) => x.connectionType === "Phone");
				const holsR: any[] = [];

				if (onlineHols.length > 0 && phoneHols.length > 0) {
					let index = 0;
					// parse online
					for (const o of onlineHols) {
						holsR.push({
							index: o.agencyHolidayConfigId,
							startDate: o.dateStart,
							endDate: o.dateEnd,
							name: o.name,
							channels: ["Online"],
							notes: o.notes
						});
						index = index + 1;
					}

					for (const o of phoneHols) {
						const f: any[] = holsR
							.filter((x) => x.name === o.name)
							.filter((x: any) => x.startDate === o.dateStart);

						if (f.length > 0) {
							const existingHol = f[0];
							holsR.splice(holsR.indexOf(existingHol), 1, {
								...existingHol,
								channels: ["Online", "Phone"],
								index: existingHol.index + "#" + o.agencyHolidayConfigId
							});
						} else {
							holsR.push({
								index: o.agencyHolidayConfigId,
								startDate: o.dateStart,
								endDate: o.dateEnd,
								name: o.name,
								channels: ["Phone"],
								notes: o.notes
							});
						}
						index = index + 1;
					}
				} else if (onlineHols.length > 0) {
					let index = 0;
					for (const o of onlineHols) {
						holsR.push({
							index: o.agencyHolidayConfigId,
							startDate: o.dateStart,
							endDate: o.dateEnd,
							name: o.name,
							channels: ["Online"],
							notes: o.notes
						});
						index = index + 1;
					}
				} else if (phoneHols.length > 0) {
					let index = 0;
					for (const o of phoneHols) {
						holsR.push({
							index: o.agencyHolidayConfigId,
							startDate: o.dateStart,
							endDate: o.dateEnd,
							name: o.name,
							channels: ["Phone"],
							notes: o.notes
						});
						index = index + 1;
					}
				}

				if (holsR.length > 0) {
					// convert to local timezone
					const hs = [];
					for (const h of holsR) {
						const dtStart = DateTime.fromISO(
							h.startDate.replace(" ", "T").split(".")[0],
							{ zone: "UTC" }
						);
						const dateStart =
							dtStart
								.setZone("UTC" + offset)
								.toString()
								.split(".")[0] + ".000Z";

						const dtEnd = DateTime.fromISO(
							h.endDate.replace(" ", "T").split(".")[0],
							{ zone: "UTC" }
						);
						const dateEnd =
							dtEnd
								.setZone("UTC" + offset)
								.toString()
								.split(".")[0] + ".000Z";

						hs.push({ ...h, startDate: dateStart, endDate: dateEnd });
					}

					setHolidays(hs);
					setLoaded(true);
				}
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve holidays",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	const onCheckboxChange = ({ target }: any) => {
		if (target.checked) {
			// add to array
			const ch = editingHoliday.channels;
			ch.push(target.value);
			const h = { ...editingHoliday, channels: ch };
			setEditingHoliday(h);
			setChannelError("");
		} else {
			// remove from array
			const ch = editingHoliday.channels.filter(
				(s: string) => s !== target.value
			);
			const h = { ...editingHoliday, channels: ch };
			setEditingHoliday(h);
		}
	};

	const formatDateTime = (dt: string) => {
		if (dt) {
			let time;
			let date;
			if (dt.includes(" ")) {
				time = dt.split(" ")[1];
				date = dt.split(" ")[0];
			} else {
				time = dt.split("T")[1];
				date = dt.split("T")[0];
			}
			let hours: string = time.split(":")[0];
			let minutes = time.split(":")[1];
			let suffix = "AM";
			if (parseInt(hours) > 12) {
				hours = (parseInt(hours) - 12).toString();
				suffix = "PM";
			}
			if (minutes.length === 1) {
				minutes = "0" + minutes;
			}
			if (hours === "00") {
				hours = "12";
			}

			const day = date.split("-")[2];
			const month = date.split("-")[1];
			const year = date.split("-")[0];
			return (
				month +
				"/" +
				day +
				"/" +
				year +
				" " +
				hours +
				":" +
				minutes +
				" " +
				suffix
			);
		}
	};

	const handleDeleteCloseConfirm = async () => {
		if (holidays) {
			if (deleteIndex.toString().includes("#")) {
				// Two to delete!
				await deleteHoliday(
					parseInt(agencyId ?? "0") || appState.agencyId,
					parseInt(deleteIndex.toString().split("#")[0])
				);
				await deleteHoliday(
					parseInt(agencyId ?? "0") || appState.agencyId,
					parseInt(deleteIndex.toString().split("#")[1])
				);
			} else if (parseInt(deleteIndex) > 0) {
				await deleteHoliday(
					parseInt(agencyId ?? "0") || appState.agencyId,
					parseInt(deleteIndex)
				);
			}

			const hols: any[] = (holidays ?? []).filter(
				(s) => s.index !== deleteIndex
			);

			setHolidays(hols);
			setDeleteIndex("");
		}

		handleDeleteClose();
	};

	const validate = () => {
		// validate
		if (holidays) {
			let isError = false;

			// check if name blank
			if (editingHoliday.name === "") {
				setNameError("Name cannot be left blank");
				isError = true;
			} else {
				setNameError("");
			}

			if (editingHoliday.startDate > editingHoliday.endDate) {
				setStartDateError("Start date cannot be after end date");
				isError = true;
			} else {
				setStartDateError("");
			}

			if (editingHoliday.channels.length === 0) {
				setChannelError("At least one channel must be selected");
				isError = true;
			}

			return isError;
		}
	};

	const handleEditCloseConfirm = async () => {
		const isError = validate();

		if (holidays && !isError) {
			setPageError(false);
			const hols: any[] = holidays.map((x) => x);

			if (editIndex < 0) {
				hols.push(editingHoliday);
				setHolidays(hols);
				setLoaded(true);
			} else {
				const ii = hols.findIndex((object) => {
					return object.index === editIndex;
				});

				const h = hols.slice(0, ii);
				const he = hols.slice(ii + 1);

				setHolidays(h.concat([{ ...editingHoliday, modified: true }], he)); // splice wasn't working for some reason so here's this monstrosity as i've ran out of patience
			}

			setEditIndex(0);
			handleEditClose();
		} else {
			setPageError(true);
		}
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		try {
			if (holidays) {
				// set the agency timezone
				const tzs = await listTimezones();

				const timezone = tzs.filter((i) => i.name === tz)[0];

				// update agency
				const agId = parseInt(agencyId ?? "0") || appState.agencyId;
				const agency = await getAgency(agId);

				await updateAgency(agId, {
					...agency,
					timezoneId: timezone.timezoneId
				});

				const offset = timezone.utcOffset;

				// convert datetimes to UTC, write to DB
				for (const h of holidays) {
					if (h.modified || parseInt(h.index) < 0) {
						let sDateTime = h.startDate;
						let eDateTime = h.endDate;

						let offsetString = "";

						if ((offset ?? 0) < 0 && (offset ?? 0).toString().length === 2) {
							offsetString = "-0" + offset?.toString().charAt(1) + ":00";
						} else if ((offset ?? 0) < 0) {
							offsetString = "-" + offset?.toString() + ":00";
						} else if (
							(offset ?? 0) >= 0 &&
							(offset ?? 0).toString().length === 1
						) {
							offsetString = "+0" + offset + ":00";
						} else {
							offsetString = "+" + offset?.toString() + ":00";
						}

						if (sDateTime.includes(" ")) {
							sDateTime =
								sDateTime.replace(" ", "T").slice(0, -3) + offsetString;
						} else if (sDateTime.includes("Z")) {
							sDateTime =
								sDateTime.replace("Z", "").slice(0, -4) +
								(((offset ?? 0) >= 0 && "+") || "") +
								offsetString;
						} else {
							sDateTime = sDateTime + ":00.000" + offsetString;
						}

						if (eDateTime.includes(" ")) {
							eDateTime =
								eDateTime.replace(" ", "T").slice(0, -3) + offsetString;
						} else if (eDateTime.includes("Z")) {
							eDateTime =
								eDateTime.replace("Z", "").slice(0, -4) + offsetString;
						} else {
							eDateTime = eDateTime + ":00.000" + offsetString;
						}

						const reverseOffset = (offset ?? 0) * -1;

						const dtStart = DateTime.fromISO(sDateTime, {
							zone: "UTC" + (((offset ?? 0) >= 0 && "+") || "") + offset
						});
						const rezonedStart = dtStart.setZone("utc").toString();

						const dtEnd = DateTime.fromISO(eDateTime, {
							zone: "UTC" + (((offset ?? 0) >= 0 && "+") || "") + offset
						});
						const rezonedEnd = dtEnd.setZone("utc").toString();

						// daft implementation due to rapid refactoring to remove race schedules, apols to whoever works on this next
						if (h.index.toString().includes("#")) {
							// applies to >1 connection type, both are updates
							const hol: Holiday = {
								agencyId: agId,
								dateStart: rezonedStart,
								dateEnd: rezonedEnd,
								notes: h.notes,
								name: h.name
							};

							await updateHoliday(agId, h.index.split("#")[0], {
								...hol,
								agencyHolidayConfigId: h.index.split("#")[0],
								connectionType: "Phone"
							});
							await updateHoliday(agId, h.index.split("#")[1], {
								...hol,
								agencyHolidayConfigId: h.index.split("#")[1],
								connectionType: "Online"
							});
						} else if (parseInt(h.index) < 0) {
							// new holiday(s) -> create
							const hol: HolidayNew = {
								agencyId: agId,
								dateStart: rezonedStart,
								dateEnd: rezonedEnd,
								notes: h.notes,
								name: h.name
							};

							for (const ct of h.channels) {
								if (ct === "Phone") {
									await createHoliday(agId, { ...hol, connectionTypeId: 1 });
								} else {
									await createHoliday(agId, { ...hol, connectionTypeId: 2 });
								}
							}
						} else if (h.channels.length > 1) {
							// existing holiday, but with a new holiday for an additional connection type -> update and create
							const hol: Holiday = {
								agencyId: agId,
								dateStart: rezonedStart,
								dateEnd: rezonedEnd,
								notes: h.notes,
								name: h.name
							};
							await updateHoliday(agId, h.index, {
								...hol,
								agencyHolidayConfigId: h.index,
								connectionType: "Phone"
							});

							const holNew: HolidayNew = {
								agencyId: agId,
								dateStart: rezonedStart,
								dateEnd: rezonedEnd,
								notes: h.notes,
								name: h.name
							};
							await createHoliday(agId, { ...holNew, connectionTypeId: 2 });
						} else {
							// existing holiday -> update
							const hol: Holiday = {
								agencyId: agId,
								dateStart: rezonedStart,
								dateEnd: rezonedEnd,
								notes: h.notes,
								name: h.name
							};

							await updateHoliday(agId, h.index, {
								...hol,
								agencyHolidayConfigId: h.index,
								connectionType: h.channels[0]
							});
						}
					}
				}

				toaster.push({
					message: "Holidays updated successfully!",
					variant: "success",
					dismissAfter: 4000
				});

				if (appState.role === "nfcc_admin") {
					navigate("/agencies/" + agencyId);
				} else {
					navigate("/home");
				}
			}
		} catch (error) {
			console.error(error);
			toaster.push({
				message: "Could not update holidays",
				variant: "error",
				dismissAfter: 4000
			});
		}
	};

	const handleTimeChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (target.id === "startDate") {
			const dt = target.value + "T" + editingHoliday.startDate.split("T")[1];
			setEditingHoliday({ ...editingHoliday, startDate: dt });
		} else if (target.id === "startTime") {
			const dt = editingHoliday.startDate.split("T")[0] + "T" + target.value;
			setEditingHoliday({ ...editingHoliday, startDate: dt });
		} else if (target.id === "endDate") {
			const dt = target.value + "T" + editingHoliday.endDate.split("T")[1];
			setEditingHoliday({ ...editingHoliday, endDate: dt });
		} else if (target.id === "endTime") {
			const dt = editingHoliday.endDate.split("T")[0] + "T" + target.value;
			setEditingHoliday({ ...editingHoliday, endDate: dt });
		}
	};

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const h = { ...editingHoliday, [target.name]: target.value };
		setEditingHoliday(h);
	};

	const handleChangeTz = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setTz(target.value);
	};

	const handleEnableDisable24hours = (e: any) => {
		if (e.target.checked) {
			const dt = editingHoliday.startDate.split("T")[0] + "T00:00";
			const dt1 = editingHoliday.endDate.split("T")[0] + "T23:59";
			setEditingHoliday({ ...editingHoliday, startDate: dt, endDate: dt1 });
		} else {
			const dt = editingHoliday.startDate.split("T")[0] + "T00:00";
			const dt1 = editingHoliday.endDate.split("T")[0] + "T00:01";
			setEditingHoliday({ ...editingHoliday, startDate: dt, endDate: dt1 });
		}
	};

	function isChecked(): boolean {
		try {
			const startTime = editingHoliday.startDate.split("T")[1];
			const endTime = editingHoliday.endDate.split("T")[1];

			if (startTime === "00:00" && endTime === "23:59") {
				return true;
			} else {
				return false;
			}
		} catch {
			return false;
		}
	}

	return (
		<>
			<Box display="flex" flexDirection="column" rowGap="space40" width="25%">
				<HeaderTitleText titleText="Manage Holidays" />
				<Label htmlFor="timezone">Schedule Timezone</Label>
				{tzs && (
					<Select
						name="timezone"
						id="timezone"
						value={tz}
						onChange={handleChangeTz}
					>
						{tzs
							? tzs &&
							tzs.map((s: any, index: number) => (
								<Option key={s.name} value={s.name}>
									{s.name}
								</Option>
							))
							: null!}
					</Select>
				)}

				<Paragraph marginBottom="space0">
					Please update with any dates that your agency does not wish to receive
					connections. To add a new holiday, click the plus button.
				</Paragraph>
			</Box>

			<Box display="flex" justifyContent="flex-end">
				<Button variant="primary" onClick={handleAddOpen}>
					<PlusIcon decorative={false} title="Add new holiday" />
					Add New Holiday
				</Button>
			</Box>
			<form onSubmit={(e) => handleSubmit(e)}>
				<Box marginY="space60">
					<Table>
						<THead>
							<Tr>
								<Th></Th>
								<Th>Start Date and Time</Th>
								<Th>End Date and Time</Th>
								<Th>Name</Th>
								<Th>Channel</Th>
								<Th>Notes</Th>
							</Tr>
						</THead>
						<TBody>
							{holidays && loaded ? (
								holidays &&
								holidays.map((h: any, rowIndex: number) => (
									<Tr key={"tr-" + rowIndex}>
										<Td key={"row-" + rowIndex}>
											<Stack
												orientation="vertical"
												spacing="space60"
												key={"stack-" + rowIndex}
											>
												<Button
													variant="secondary"
													onClick={(e) => handleEditOpen(h.index)}
													key={"b1-" + rowIndex}
												>
													<EditIcon title="Edit Holiday" decorative={false} />
												</Button>
												<Button
													variant="destructive"
													onClick={(e) => handleDeleteOpen(h.index)}
													key={"b2-" + rowIndex}
												>
													<DeleteIcon
														title="Delete Holiday"
														decorative={false}
													/>
												</Button>
											</Stack>
										</Td>
										<Td key={"startDate-" + rowIndex}>
											<Paragraph marginBottom="space0">
												{formatDateTime(h.startDate)}
											</Paragraph>
										</Td>
										<Td key={"endDate-" + rowIndex}>
											<Text as="p">
												{h.endDate && formatDateTime(h.endDate)}
											</Text>
										</Td>
										<Td key={"name-" + rowIndex}>
											<Text as="p">{h.name}</Text>
										</Td>
										<Td key={"channels-" + rowIndex}>
											<Text as="p">{h.channels.join(", ")}</Text>
										</Td>
										<Td key={"notes-" + rowIndex}>
											<Text as="p">{h.notes}</Text>
										</Td>
									</Tr>
								))
							) : (
								<Tr>
									<Td colSpan={6}>No data to display</Td>
								</Tr>
							)}
						</TBody>
					</Table>
					<Box
						display="flex"
						justifyContent="flex-end"
						position="relative"
						marginY="space60"
					>
						<Button variant="primary" type="submit">
							Save
						</Button>
					</Box>
				</Box>
			</form>

			{/* Add/Edit a Holiday modal */}
			<Modal
				isOpen={editIsOpen}
				onDismiss={handleEditClose}
				size="wide"
				ariaLabelledby={editModalHeadingID}
			>
				<ModalHeader>
					<ModalHeading as="h2" id={editModalHeadingID}>
						Configure Holiday
					</ModalHeading>
				</ModalHeader>

				<ModalBody>
					{pageError && (
						<Box paddingTop="space60" paddingBottom="space60">
							<Alert variant="error">
								<Text as="span">
									Validation errors have been found on this holiday, please
									check these before continuing
								</Text>
							</Alert>
						</Box>
					)}

					{editingHoliday && (
						<>
							<Heading as="h4" variant="heading40">
								Please select start date and time, end date and time, label and
								any relevant notes.
							</Heading>

							<Box
								display="flex"
								flexDirection="column"
								marginY="space60"
								padding="space60"
							>
								<Checkbox
									key="24h"
									id="24h"
									checked={isChecked()}
									name="24h"
									onClick={(e) => handleEnableDisable24hours(e)}
								>
									24 hours?
								</Checkbox>

								<Box
									display="flex"
									flexDirection="row"
									marginY="space60"
									justifyContent="space-between"
									alignItems="center"
								>
									<Box display="flex">
										<Label htmlFor="startDate">Start Date and Time</Label>
									</Box>
									<Box
										display="flex"
										justifyContent="flex-end"
										columnGap="space60"
									>
										<Box display="flex" flexDirection="column">
											<DatePicker
												onChange={handleTimeChange}
												required
												id="startDate"
												enterKeyHint={undefined}
												value={editingHoliday.startDate.split("T")[0]}
											/>
										</Box>
										<TimePicker
											required
											id="startTime"
											name="startTime"
											value={
												editingHoliday.startDate.split("T")[1].split(".")[0]
											}
											onChange={handleTimeChange}
											enterKeyHint={undefined}
										/>
									</Box>
								</Box>

								<Box
									display="flex"
									flexDirection="row"
									marginY="space60"
									justifyContent="space-between"
									alignItems="center"
								>
									<Box display="flex">
										<Label htmlFor="endDate">End Date and Time</Label>
									</Box>
									<Box
										display="flex"
										justifyContent="flex-end"
										columnGap="space60"
									>
										<DatePicker
											onChange={handleTimeChange}
											required
											id="endDate"
											enterKeyHint={undefined}
											value={editingHoliday.endDate.split("T")[0]}
										/>

										<TimePicker
											required
											id="endTime"
											name="endTime"
											value={editingHoliday.endDate.split("T")[1].split(".")[0]}
											onChange={handleTimeChange}
											enterKeyHint={undefined}
										/>
									</Box>
								</Box>

								{startDateError.length > 0 && (
									<Box display="flex" justifyContent="flex-end">
										<HelpText variant="error">{startDateError}</HelpText>
									</Box>
								)}

								<Box
									display="flex"
									flexDirection="row"
									marginY="space60"
									justifyContent="space-between"
									alignItems="center"
								>
									<Box display="flex">
										<Label htmlFor="name">Name</Label>
									</Box>
									<Box display="flex" flexDirection="column">
										<Box
											display="flex"
											justifyContent="flex-end"
											columnGap="space60"
											width="25vw"
											flexDirection="row"
										>
											<Input
												type="text"
												onChange={handleChange}
												id="name"
												name="name"
												value={editingHoliday.name}
												required
												hasError={nameError.length > 0}
											/>
										</Box>
										{nameError.length > 0 && (
											<HelpText variant="error">{nameError}</HelpText>
										)}
									</Box>
								</Box>

								<Box
									display="flex"
									flexDirection="row"
									marginY="space60"
									justifyContent="space-between"
									alignItems="center"
								>
									<Box display="flex">
										<Label htmlFor="channel">Channel</Label>
									</Box>
									<Box display="flex" justifyContent="flex-end">
										<CheckboxGroup
											orientation="horizontal"
											name="chk"
											legend=""
											helpText=""
											key="channels"
										>
											{editingHoliday &&
												["Phone", "Online"].map((s: any, index: number) => (
													<Checkbox
														key={s}
														id={s}
														checked={
															editingHoliday.channels.includes(s) || false
														}
														value={s}
														name={s}
														onClick={(e) => onCheckboxChange(e)}
													>
														{s}
													</Checkbox>
												))}
										</CheckboxGroup>
									</Box>
								</Box>

								{channelError.length > 0 && (
									<Box display="flex" justifyContent="flex-end">
										<HelpText variant="error">{channelError}</HelpText>
									</Box>
								)}

								<Box
									display="flex"
									flexDirection="row"
									marginY="space60"
									alignItems="center"
									justifyContent="space-between"
								>
									<Box display="flex">
										<Label htmlFor="notes">Notes</Label>
									</Box>
									<Box
										display="flex"
										justifyContent="flex-end"
										columnGap="space60"
										width="25vw"
									>
										<Input
											placeholder=""
											name="notes"
											onChange={handleChange}
											type="text"
											value={editingHoliday.notes}
										/>
									</Box>
								</Box>
							</Box>
						</>
					)}
				</ModalBody>

				<ModalFooter>
					<ModalFooterActions>
						<Button variant="secondary" onClick={handleEditClose}>
							Cancel
						</Button>
						<Button variant="primary" onClick={handleEditCloseConfirm}>
							Submit
						</Button>
					</ModalFooterActions>
				</ModalFooter>
			</Modal>

			{/* Delete Holiday modal */}
			<Modal
				isOpen={deleteIsOpen}
				onDismiss={handleDeleteClose}
				size={"default"}
				ariaLabelledby={deleteModalHeadingID}
			>
				<ModalHeader>
					<ModalHeading as="h2" id={deleteModalHeadingID}>
						Delete Holiday
					</ModalHeading>
				</ModalHeader>

				<ModalBody>
					<Box marginY="space60">
						<Text as="p">Are you sure want to delete this holiday?</Text>
					</Box>
				</ModalBody>

				<ModalFooter>
					<ModalFooterActions>
						<Button variant="secondary" onClick={handleDeleteClose}>
							Cancel
						</Button>
						<Button variant="destructive" onClick={handleDeleteCloseConfirm}>
							Delete Holiday
						</Button>
					</ModalFooterActions>
				</ModalFooter>
			</Modal>
		</>
	);
};
