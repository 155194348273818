import { FC } from "react";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";

export const StepperStage1: FC = () => {
	return (
		<Box display="flex" justifyContent="center">
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				width="80%"
			>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Box
						borderStyle="solid"
						borderRadius="borderRadiusCircle"
						borderColor="colorBorderPrimaryStronger"
						height="30px"
						width="30px"
						backgroundColor="colorBackgroundBrandStrong"
					>
						<Text
							fontSize="fontSize40"
							fontWeight="fontWeightBold"
							textAlign="center"
							as="p"
							color="colorTextWeakest"
							lineHeight="lineHeight40"
						>
              1
						</Text>
					</Box>
					<Box display="flex" paddingTop="space20" width="max-content">
						<Text as="span" lineHeight="lineHeight40">
              Service Selection
						</Text>
					</Box>
				</Box>

				<Box
					display="flex"
					width="150px"
					borderBottomStyle="solid"
					borderBottomColor="colorBorderWeak"
					marginBottom="space60"
					marginRight="space100"
				/>

				<Box display="flex" flexDirection="column" alignItems="center">
					<Box
						borderStyle="solid"
						borderRadius="borderRadiusCircle"
						borderColor="colorBorder"
						backgroundColor="colorBackgroundOverlay"
						height="30px"
						width="30px"
					>
						<Text
							fontSize="fontSize40"
							fontWeight="fontWeightBold"
							textAlign="center"
							as="p"
							color="colorText"
							lineHeight="lineHeight40"
						>
              2
						</Text>
					</Box>
					<Box display="flex" paddingTop="space20" width="max-content">
						<Text as="p" lineHeight="lineHeight40">
              Channel Selection
						</Text>
					</Box>
				</Box>

				<Box
					display="flex"
					width="150px"
					borderBottomStyle="solid"
					borderBottomColor="colorBorderWeak"
					marginBottom="space60"
					marginRight="space100"
				/>

				<Box display="flex" flexDirection="column" alignItems="center">
					<Box
						borderStyle="solid"
						borderRadius="borderRadiusCircle"
						borderColor="colorBorder"
						backgroundColor="colorBackgroundOverlay"
						height="30px"
						width="30px"
					>
						<Text
							fontSize="fontSize40"
							fontWeight="fontWeightBold"
							textAlign="center"
							as="p"
							color="colorText"
							lineHeight="lineHeight40"
						>
              3
						</Text>
					</Box>
					<Box display="flex" paddingTop="space20" width="max-content">
						<Text as="p" lineHeight="lineHeight40">
              Service Setup
						</Text>
					</Box>
				</Box>

				<Box
					display="flex"
					width="150px"
					borderBottomStyle="solid"
					borderBottomColor="colorBorderWeak"
					marginBottom="space60"
				/>

				<Box display="flex" flexDirection="column" alignItems="center">
					<Box
						borderStyle="solid"
						borderRadius="borderRadiusCircle"
						borderColor="colorBorder"
						backgroundColor="colorBackgroundOverlay"
						height="30px"
						width="30px"
					>
						<Text
							fontSize="fontSize40"
							fontWeight="fontWeightBold"
							lineHeight="lineHeight40"
							textAlign="center"
							as="p"
							color="colorText"
						>
              4
						</Text>
					</Box>

					<Box display="flex" paddingTop="space20" width="max-content">
						<Text as="p" lineHeight="lineHeight40">
              Confirmation &#x26; Publishing
						</Text>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
