import { FC } from "react";
import { Box } from "@twilio-paste/core/box";
import { Heading } from "@twilio-paste/core/heading";
import { SubtitleTitleTextType } from "../../interface";

export const SubtitleText: FC<SubtitleTitleTextType> = ({ subtitleText }) => {
	return (
		<>
			<Box>
				<Heading as="h2" variant="heading20">
					{subtitleText}
				</Heading>
			</Box>
		</>
	);
};