import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Audits, User, Users } from "@ciptex/nfcc";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Button } from "@twilio-paste/core/button";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { DateTime } from "luxon";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Select, Option } from "@twilio-paste/core/select";
import { Label } from "@twilio-paste/core/label";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { HelpText } from "@twilio-paste/core/help-text";

import { Stack } from "@twilio-paste/core/stack";
import { DatePicker, formatReturnDate } from "@twilio-paste/core/date-picker";
import { Scroller } from "../Scroller/Scroller";

export const AuditsTable: FC = () => {
	const [audits, setAudits] = useState<Audits>();
	const [filteredAudits, setFilteredAudits] = useState<Audits>();
	const [agenciesFilter, setAgenciesFilter] = useState<string[]>();
	const [identifierFilter, setIdentifierFilter] = useState<string[]>();
	const [entityTypeFilter, setEntityTypeFilter] = useState<string[]>();
	const [agencyFilterValue, setAgencyFilterValue] =
		useState<string>("All Agencies");
	const [identifierFilterValue, setIdentifierFilterValue] =
		useState<string>("All Identifiers");
	const [userTypeFilterValue, setUserTypeFilterValue] =
		useState<string>("All Types");
	const [entityTypeFilterValue, setEntityTypeFilterValue] =
		useState<string>("All Entity Types");
	const [loaded, setLoaded] = useState<boolean>(false);
	const [startDate, setStartDate] = useState<string>();
	const [endDate, setEndDate] = useState<string>();
	const [dateError, setDateError] = useState<string>("");
	const [preDateError, setPreDateError] = useState<string>("");
	const [tz, setTz] = useState<string>();

	const { listAudits, listUsersEmailFilter, getUser } = useNFCCContext();
	const { appState } = useAppState();
	const { toaster } = useToasterContext();

	const getDate = () => {
		const today = DateTime.local();
		const todayDate = today.toFormat("dd-MM-yyyy-HH-mm-ss");
		return todayDate;
	};

	const tzs = [
		{ name: "EST", tag: "America/New_York" },
		{ name: "EDT", tag: "America/New_York" },
		{ name: "CST", tag: "America/Chicago" },
		{ name: "CDT", tag: "America/Chicago" },
		{ name: "MST", tag: "America/Denver" },
		{ name: "MDT", tag: "America/Denver" },
		{ name: "PST", tag: "America/Los_Angeles" },
		{ name: "PDT", tag: "America/Los_Angeles" },
		{ name: "HST", tag: "Pacific/Honolulu" },
		{ name: "HSDT", tag: "Pacific/Honolulu" },
		{ name: "AKST", tag: "America/Anchorage" },
		{ name: "AKDT", tag: "America/Anchorage" },
		{ name: "AST", tag: "America/Halifax" },
		{ name: "ADT", tag: "America/Halifax" },
		{ name: "EGST", tag: "Europe/London" },
		{ name: "EGT", tag: "Europe/London" },
		{ name: "GMT", tag: "Europe/London" },
		{ name: "NST", tag: "America/St_Johns" },
		{ name: "NSDT", tag: "America/St_Johns" },
		{ name: "PMDT", tag: "America/Miquelon" },
		{ name: "PMST", tag: "America/Miquelon" },
		{ name: "WGST", tag: "America/Godthab" },
		{ name: "WGT", tag: "America/Godthab" }
	];

	const exportTableData = () => {
		if (filteredAudits) {
			const csvData = [
				[
					"Date and Time",
					"Agency ID",
					"Agency",
					"Entity Type",
					"Identifier",
					"Field Name",
					"Original Value",
					"New Value",
					"Changed By",
					"Changed By Type"
				],
				...filteredAudits.map(
					({
						changeDateTime,
						netsuiteRef,
						agencyName,
						entityType,
						identifier,
						fieldName,
						originalValue,
						newValue,
						changedBy,
						changedByType
					}) => [
						changeDateTime,
						netsuiteRef,
						agencyName,
						entityType,
						identifier,
						fieldName,
						originalValue?.replaceAll(",", ";"),
						newValue?.replaceAll(",", ";"),
						changedBy,
						changedByType
					]
				)
			];

			const csv = csvData.map((row) => row.join(",")).join("\n");
			const csvBlob = new Blob([csv], { type: "text/csv" });
			const csvUrl = URL.createObjectURL(csvBlob);
			const downloadLink = document.createElement("a");
			downloadLink.href = csvUrl;
			downloadLink.download = `audits-${getDate()}.csv`;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	};

	function getMonthFromString(mon: string) {
		let s = (new Date(Date.parse(mon + " 1, 2012")).getMonth() + 1).toString();
		if (s.length === 1) {
			s = "0" + s;
		}
		return s;
	}

	useEffect(() => {
		(async () => {
			try {
				const users: Users = await listUsersEmailFilter(
					encodeURI(appState.email)
				);
				const u: User = await getUser(users[0].userId ?? 0);
				const timezone = (u.timezone as any)?.timezone ?? "EST";
				const tz = tzs.find((t) => t.name === timezone);
				setTz(tz?.tag);

				const d = new Date();
				const endDate = d.toISOString().substring(0, 10);
				setEndDate(endDate);
				d.setDate(d.getDate() - 3);
				const startDate = d.toISOString().substring(0, 10);
				setStartDate(startDate);

				const data: Audits = await listAudits(startDate, endDate);
				setAudits(data);
				setFilteredAudits(data);

				// set up filters
				setAgenciesFilter([
					...new Set(data.map(({ agencyName }) => agencyName ?? ""))
				]);
				setIdentifierFilter([
					...new Set(data.map(({ identifier }) => identifier ?? ""))
				]);
				setEntityTypeFilter([
					...new Set(data.map(({ entityType }) => entityType ?? ""))
				]);

				if ((data as any).error_message) {
					console.error("error");
					toaster.push({
						message:
							"Currently there are no audits in this filter range",
						variant: "neutral",
						dismissAfter: 4000
					});
				} else {
					setLoaded(true);
				}

				if (data) {
					const auditsNew: Audits = [];
					// For each in data do new dateTime and setZone
					for (const audit of data) {
						const oldDT = audit.changeDateTime;
						const oldISO =
								oldDT?.split(" ")[2] +
								"-" +
								getMonthFromString(oldDT?.split(" ")[1] ?? "") +
								"-" +
								oldDT?.split(" ")[0] +
								"T" +
								oldDT?.split(" ")[3] +
								":00+00:00";
						const changeDateTime = DateTime.fromISO(oldISO, {
							zone: tz?.tag
						});
						audit.changeDateTime = changeDateTime.toFormat("dd MMM yyyy HH:mm");
						auditsNew.push(audit);
					}
					setAudits(auditsNew);
					setFilteredAudits(auditsNew);
				}
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve audit data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	const applyFilters = async (e: any) => {
		if (audits && startDate && endDate) {
			if (startDate > endDate) {
				setDateError("End date cannot be before start date");
				setPreDateError("Please fix error");
			} else {
				setDateError("");
				setPreDateError("");

				let filtered = await listAudits(startDate ?? "", endDate ?? "");

				if ((filtered as any).error_message) {
					setLoaded(false);
					toaster.push({
						message:
							"Currently there are no audits in this filter range",
						variant: "neutral",
						dismissAfter: 4000
					});
				} else {
					if (filtered) {
						const auditsNew: Audits = [];
						// For each in data do new dateTime and setZone
						for (const audit of filtered) {
							const oldDT = audit.changeDateTime;
							const oldISO =
								oldDT?.split(" ")[2] +
								"-" +
								getMonthFromString(oldDT?.split(" ")[1] ?? "") +
								"-" +
								oldDT?.split(" ")[0] +
								"T" +
								oldDT?.split(" ")[3] +
								":00+00:00";
							// const changeDateTime = DateTime.fromFormat(audit.changeDateTime, "dd MMM yyyy HH:mm").setZone(tz);
							const changeDateTime = DateTime.fromISO(oldISO, {
								zone: tz
							});
							audit.changeDateTime =
								changeDateTime.toFormat("dd MMM yyyy HH:mm");
							auditsNew.push(audit);
						}
						setAudits(auditsNew);
						setFilteredAudits(auditsNew);
					}
					setLoaded(true);

					if (agencyFilterValue !== "All Agencies") {
						filtered = filtered.filter(
							(a) => (a.agencyName ?? "") === agencyFilterValue
						);
					}

					if (identifierFilterValue !== "All Identifiers") {
						filtered = filtered.filter(
							(a) => (a.identifier ?? "") === identifierFilterValue
						);
					}

					if (entityTypeFilterValue !== "All Entity Types") {
						filtered = filtered.filter(
							(a) => (a.entityType ?? "") === entityTypeFilterValue
						);
					}

					if (userTypeFilterValue !== "All Types") {
						filtered = filtered.filter(
							(a) => a.changedByType === userTypeFilterValue
						);
					}

					setFilteredAudits(filtered);

					// set up filters
					setAgenciesFilter([
						...new Set(filtered.map(({ agencyName }) => agencyName ?? ""))
					]);
					setIdentifierFilter([
						...new Set(filtered.map(({ identifier }) => identifier ?? ""))
					]);
					setEntityTypeFilter([
						...new Set(filtered.map(({ entityType }) => entityType ?? ""))
					]);
				}
			}
		}
	};

	const handleFilterChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (target.name === "agency") {
			setAgencyFilterValue(target.value);
		} else if (target.name === "identifier") {
			setIdentifierFilterValue(target.value);
		} else if (target.name === "entityType") {
			setEntityTypeFilterValue(target.value);
		} else if (target.name === "changedByType") {
			setUserTypeFilterValue(target.value);
		}
	};

	const handleChange = (name: string, val: string, format: string) => {
		const d = formatReturnDate(val, format);

		if (name === "startDatePicker") {
			setStartDate(d);
		} else {
			setEndDate(d);
		}
	};

	return (
		<Box width="100%">
			<Scroller />

			<HeaderTitleText titleText="Configuration Change History" />

			<Box display="flex" justifyContent="flex-end" marginY="space60">
				<Button variant="secondary" onClick={exportTableData}>
					Download CSV
					<DownloadIcon decorative={false} title="download csv icon" />
				</Button>
			</Box>

			<Box
				display="flex"
				flexDirection="row"
				marginY="space60"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<Box
					display="flex"
					flexDirection="row"
					columnGap="space60"
					alignItems="flex-start"
					flexWrap="wrap"
				>
					{/* TODO - Align items so on error the start date lines up with rest of filter - hacked for now with extra error message */}
					<Box display="flex" flexDirection="row" alignItems="flex-start">
						<Stack orientation="horizontal" spacing="space80">
							<Box>
								<Label htmlFor="startDatePicker">Start date</Label>
								<DatePicker
									hasError={preDateError.length > 0}
									id="startDatePicker"
									name="startDatePicker"
									onChange={(evt) =>
										handleChange(
											evt.target.name,
											evt.target.value,
											"yyyy-MM-dd"
										)
									}
									enterKeyHint={null!}
									value={startDate}
								/>
								{preDateError.length > 0 && (
									<HelpText variant="error">{preDateError}</HelpText>
								)}
							</Box>
							<Box>
								<Label htmlFor="endDatePicker">End date</Label>
								<DatePicker
									hasError={dateError.length > 0}
									id="endDatePicker"
									name="endDatePicker"
									onChange={(evt) =>
										handleChange(
											evt.target.name,
											evt.target.value,
											"yyyy-MM-dd"
										)
									}
									enterKeyHint={null!}
									value={endDate}
								/>
								{dateError.length > 0 && (
									<HelpText variant="error">{dateError}</HelpText>
								)}
							</Box>
						</Stack>
					</Box>
					<Box display="flex" flexDirection="column" width="15vw">
						<Label htmlFor="agency">Agency</Label>
						<Select
							id="agency"
							name="agency"
							onChange={handleFilterChange}
							value={agencyFilterValue}
						>
							<Option value="All Agencies">All Agencies</Option>
							{agenciesFilter && loaded
								? agenciesFilter &&
								agenciesFilter.map((s: any, index: number) => (
									<Option key={index} value={s}>
										{s}
									</Option>
								))
								: null!}
						</Select>
					</Box>

					<Box display="flex" flexDirection="column" width="15vw">
						<Label htmlFor="entityType">Entity Type</Label>
						<Select
							id="entityType"
							name="entityType"
							onChange={handleFilterChange}
							value={entityTypeFilterValue}
						>
							<Option value="All Entity Types">All Entity Types</Option>
							{entityTypeFilter && loaded
								? entityTypeFilter &&
								entityTypeFilter.map((s: any, index: number) => (
									<Option key={index} value={s}>
										{s}
									</Option>
								))
								: null!}
						</Select>
					</Box>

					{/* <Box display="flex" flexDirection="column" width="15vw">
					<Label htmlFor="identifier">Identifier</Label>
					<Select id="identifier" name="identifier" onChange={handleFilterChange} value = {identifierFilterValue}>
						<Option value="All Identifiers">All Identifiers</Option>
						{identifierFilter && loaded ? (

							identifierFilter && identifierFilter.map((s: any, index: number) => (

								<Option key={index} value={s} >{s}</Option>

							))) : null!}
					</Select>
							</Box>*/}

					<Box display="flex" flexDirection="column" width="15vw">
						<Label htmlFor="changedByType">Changed By Type</Label>
						<Select
							id="changedByType"
							name="changedByType"
							onChange={handleFilterChange}
							value={userTypeFilterValue}
						>
							<Option value="All Types">All Types</Option>
							<Option value="NFCC">NFCC</Option>
							<Option value="Agency">Agency</Option>
						</Select>
					</Box>
				</Box>

				<Box
					display="flex"
					justifyContent="flex-end"
					paddingTop="space70"
					height="fit-content"
				>
					<Button variant="primary" onClick={applyFilters}>
						<FilterIcon decorative={false} title="Apply filters" />
						Apply
					</Button>
				</Box>
			</Box>

			<Table aria-label="Audits table" striped>
				<THead>
					<Tr>
						<Th>Date and Time</Th>
						<Th>Agency ID</Th>
						<Th>Agency</Th>
						<Th>Entity Type</Th>
						<Th>Identifier</Th>
						<Th>Field Name</Th>
						<Th>Original Value</Th>
						<Th>New Value</Th>
						<Th>Changed By</Th>
						<Th>Changed By Type</Th>
					</Tr>
				</THead>
				{loaded ? (
					<TBody>
						{filteredAudits && loaded ? (
							filteredAudits &&
							filteredAudits.map((audit: any, rowIndex: number) => (
								<Tr key={rowIndex}>
									<Td>{audit.changeDateTime}</Td>
									<Td>{audit.netsuiteRef}</Td>
									<Td>{audit.agencyName}</Td>
									<Td>{audit.entityType}</Td>
									<Td>{audit.identifier}</Td>
									<Td>{audit.fieldName}</Td>
									<Td>{audit.originalValue}</Td>
									<Td>{audit.newValue}</Td>
									<Td>{audit.changedBy}</Td>
									<Td>{audit.changedByType}</Td>
								</Tr>
							))
						) : (
							<Tr>
								<Td colSpan={10}>No data to display</Td>
							</Tr>
						)}
					</TBody>
				) : (
					<TBody>
						<TableSkeletonLoader numberOfTr={4} numberOfTd={10} />
					</TBody>
				)}

			</Table>
		</Box>
	);
};
