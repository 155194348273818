import { FC } from "react";
import { Heading } from "@twilio-paste/core/heading";
import { Box } from "@twilio-paste/core/box";
import { Card } from "@twilio-paste/core/card";
import { Paragraph } from "@twilio-paste/core/paragraph";
import { Button } from "@twilio-paste/core/button";
import { Flex } from "@twilio-paste/core/flex";
import { Separator } from "@twilio-paste/core/separator";
import { useNavigate } from "react-router-dom";
import { Alert } from "@twilio-paste/core";

export const WizardStart: FC = () => {
	const navigate = useNavigate();

	return (
		<>
			<Alert variant={"error"}>
        You need to complete the whole process in this wizard, otherwise any
        progress will be lost. <br /> The process will be completed after you have
        reached the Go Live Page and made a selection.
			</Alert>
			<Flex vAlignContent="center" hAlignContent="center" paddingTop="space200">
				<Box display="flex">
					<Card>
						<Heading as="h1" variant="heading10">
              Service Setup Wizard
						</Heading>
						<Separator orientation="horizontal" verticalSpacing="space60" />
						<Paragraph>
              This wizard will take you through a step by step process to set up
              the services you want to offer.
						</Paragraph>
						<Separator orientation="horizontal" verticalSpacing="space60" />
						<Box display="flex" justifyContent="flex-end">
							<Button variant="primary" onClick={() => navigate("selection")}>
                Get Started
							</Button>
						</Box>
					</Card>
				</Box>
			</Flex>
		</>
	);
};
