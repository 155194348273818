/* eslint @typescript-eslint/no-var-requires: "off" */

import { ChangeEvent, FC, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@twilio-paste/core/box";
import { useHeight } from "../../hooks/useHeight/useHeight";
import { Heading } from "@twilio-paste/core/heading";
import { UserAuth } from "@ciptex/nfcc";
import { Label } from "@twilio-paste/core/label";
import { Input } from "@twilio-paste/core/input";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { HelpText } from "@twilio-paste/core/help-text";
import { Button } from "@twilio-paste/core/button";
import { useToasterContext } from "../../hooks/useToasterContext";

export const EmailReset: FC = () => {

	const height = useHeight();
	const navigate = useNavigate();
	const [alert, setAlert] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { signIn, token, appDispatch, appState } = useAppState();

	const [form, setForm] = useState<UserAuth>({
		username: ""
	});

	const { toaster } = useToasterContext();

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();


		try {
			const axios = require("axios");

			console.log('CAZ',location.href)

			let base_url = "https://lg2k1xouyd.execute-api.us-east-1.amazonaws.com/user/resetpassword?email="

			if(location.href.includes('localhost') || location.href.includes('lamp-demo.pages.dev/') )
			{
				base_url = "https://53oy29qee3.execute-api.eu-west-2.amazonaws.com/user/resetpassword?email="
			}
			const config = {
				method: "get",
				url: base_url+form.username+"&source="+location.href,
				headers: {
					"Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzk1ZTM0OGIwYzczNGQyZTIyOGVhZTZjYjhkMzc1ZGU3LTE2NDg1NjA2MDMiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJuZmNjQGFkbWluLnVzZXIiLCJjaXB0ZXgiOnsiY2lwdGV4X3Njb3BlIjpbIm5mY2NfYWRtaW4iXX19LCJpYXQiOjE2NDg1NjA2MDMsImV4cCI6MTY0ODU3NTAwMywiaXNzIjoiU0s5NWUzNDhiMGM3MzRkMmUyMjhlYWU2Y2I4ZDM3NWRlNyIsInN1YiI6IkFDNjNiM2Y2ZGY3YTQwMzY3YWEwZDBmMDZjYmZmNmVmY2EifQ.Tska8zgJfvAfvRS5KKlodYx0On5cIYSXDO-pxNTuUz0"
				} 
			};

			axios(config)
				.then((response : any) => {
					toaster.push({
						message: "Password reset link sent to "+form.username,
						variant: "success",
						dismissAfter: 4000
					});
				})
				.catch((error : any) => {
					console.error(error);
				});


		} catch (error) {
			setIsLoading(false);
			setAlert(true);
		}
	};

	const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [name]: value });
	};

	return (
		<Box height={height} display="flex" flexDirection="column" flex={1} alignContent="center" justifyContent="center" alignItems="center" justifyItems="center">
			<Box borderColor="colorBorder" borderWidth="borderWidth10" borderStyle="solid" width={["100%", "30rem", "30rem"]} borderRadius="borderRadius30" padding="space100">
				<form onSubmit={onSubmit}>
					{/* {alert && <Box marginY="space30">
						<Alert variant="neutral">
							<Text as="span">Password changed</Text>
						</Alert>
					</Box>} */}
					<Heading as="h2" variant="heading10">Password Reset</Heading>

					<Box marginY="space60">
						<Label htmlFor="username" required>Enter Email</Label>
						<Input
							aria-describedby="username_help_text"
							id="username"
							name="username"
							onChange={onChange}
							value={form.username}
							type="email"
							required
						/>
						<HelpText id="password_help_text">Please enter your email to receive a password reset link by email</HelpText>
					</Box>

					<Box marginTop="space60" float="right">
						<Button variant="primary" type="submit" loading={isLoading}>Change Password</Button>
					</Box>
				</form>
			</Box>
		</Box>);
};
