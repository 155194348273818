/* eslint @typescript-eslint/no-var-requires: "off" */
import { ChangeEvent, FC, FormEvent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Box } from "@twilio-paste/core/box";
import { Alert } from "@twilio-paste/core/alert";
import { useHeight } from "../../hooks/useHeight/useHeight";
import { Heading } from "@twilio-paste/core/heading";
import { UserAuth } from "@ciptex/nfcc";
import { Label } from "@twilio-paste/core/label";
import { Text } from "@twilio-paste/core/text";
import { Input } from "@twilio-paste/core/input";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { HelpText } from "@twilio-paste/core/help-text";
import { Button } from "@twilio-paste/core/button";
import { Anchor } from "@twilio-paste/core";
import { useToasterContext } from "../../hooks/useToasterContext";
import { ShowIcon } from "@twilio-paste/icons/esm/ShowIcon";
import { HideIcon } from "@twilio-paste/icons/esm/HideIcon";

export const PasswordReset: FC = () => {

	const height = useHeight();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { signIn, token, appDispatch, appState } = useAppState();
	const [searchParams, setSearchParams] = useSearchParams();
	const [passwordMatchExistingError, setPasswordMatchExistingError] = useState<boolean>(false);
	const { toaster } = useToasterContext();
	const [passwordShown1, setPasswordShown1] = useState(false);
	const [passwordShown2, setPasswordShown2] = useState(false);


	const [form, setForm] = useState<any>({
		password1: "",
		password2: ""
	});

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();


		if (form.password1 != form.password2) {
			setPasswordMatchExistingError(true)
		}
		else {
			setIsLoading(true);
			setPasswordMatchExistingError(false)
			const usernameB64 = searchParams.get("u");
			const hash = searchParams.get("h");

			const axios = require("axios");
			const data = JSON.stringify({ "base64username": usernameB64, "h": hash, "newPassword": form.password1 });

			let base_url = "https://lg2k1xouyd.execute-api.us-east-1.amazonaws.com/user/resetpassword"

			if(location.href.includes('localhost') || location.href.includes('lamp-demo.pages.dev/') )
			{
				base_url = "https://53oy29qee3.execute-api.eu-west-2.amazonaws.com/user/resetpassword"
			}

			const config = {
				method: "post",
				url: base_url,
				headers: {
					"Content-Type": "application/json"
				},
				data: data
			};

			axios(config)
				.then((response: any) => {
					toaster.push({
						message: "Password updated successfully!",
						variant: "success",
						dismissAfter: 4000
					});
					navigate("/login");
				})
				.catch((error: any) => {
					console.error(error);
					toaster.push({
						message: "Could not update password. Please contact your administrator",
						variant: "error",
						dismissAfter: 4000
					});
				});
		}

	};

	const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [name]: value });
	};

	const togglePassword1 = () => {
		setPasswordShown1(!passwordShown1);
	};

	const togglePassword2 = () => {
		setPasswordShown2(!passwordShown2);
	};

	return (
		<Box height={height} display="flex" flexDirection="column" flex={1} alignContent="center" justifyContent="center" alignItems="center" justifyItems="center">
			<Box borderColor="colorBorder" borderWidth="borderWidth10" borderStyle="solid" width={["100%", "30rem", "30rem"]} borderRadius="borderRadius30" padding="space100">
				<form onSubmit={onSubmit}>

					<Heading as="h2" variant="heading10">Password Reset</Heading>

					<Box marginY="space60">
						<Label htmlFor="password" required>New Password</Label>
						<Box display="flex" flexDirection="row">
							<Input
								aria-describedby="password_help_text"
								id="password1"
								name="password1"
								onChange={onChange}
								value={form.password}
								autoComplete="new-password"
								type={passwordShown1 ? "text" : "password"}
								required
							/>
							<Box marginLeft="spaceNegative100">
								<Button variant="secondary_icon" size="icon" onClick={togglePassword1}>
									{passwordShown1 ? <HideIcon decorative={false} title="Hide password 1" /> : <ShowIcon decorative={false} title="Show password 1" />}
								</Button>
							</Box>
						</Box>
						<HelpText id="password_help_text">Please enter a valid password.</HelpText>
					</Box>

					<Box marginY="space60">
						<Label htmlFor="password" required>Confirm New Password</Label>
						<Box display="flex" flexDirection="row">
							<Input
								aria-describedby="password_help_text"
								id="password2"
								name="password2"
								onChange={onChange}
								value={form.password}
								autoComplete="new-password"
								type={passwordShown2 ? "text" : "password"}
								required
							/>
							<Box marginLeft="spaceNegative100">
								<Button variant="secondary_icon" size="icon" onClick={togglePassword2}>
									{passwordShown2 ? <HideIcon decorative={false} title="Hide password 2" /> : <ShowIcon decorative={false} title="Show password 2" />}
								</Button>
							</Box>
						</Box>
						<HelpText id="password_help_text">Please confirm your new password.</HelpText>
						{passwordMatchExistingError && <HelpText variant="error">New passwords do not match</HelpText>}
					</Box>

					<Box marginTop="space60" float="right">
						<Button variant="primary" type="submit" loading={isLoading}>Change Password</Button>
					</Box>
				</form>
			</Box>
		</Box>);
};
