import { FC } from "react";
import { Box } from "@twilio-paste/core/box";
import { Heading } from "@twilio-paste/core/heading";
import { HeaderTitleTextType } from "../../interface";

export const HeaderTitleText: FC<HeaderTitleTextType> = ({ titleText }) => {
	return (
		<>
			<Box>
				<Heading as="h1" variant="heading10">
					{titleText}
				</Heading>
			</Box>
		</>
	);
};