// react imports
import { ChangeEvent, FC, useEffect, useState } from "react";
import { generatePath, Link, useLocation, useParams } from "react-router-dom";

// nfcc components etc imports
import { StepperStage3 } from "./StepperStage3";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Templates, Agency } from "@ciptex/nfcc";
import { useAppState } from "../../hooks/useAppState/useAppState";

// paste imports
import { Heading } from "@twilio-paste/core/heading";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Checkbox, CheckboxGroup } from "@twilio-paste/core/checkbox";
import { Select, Option } from "@twilio-paste/core/select";
import { TimePicker } from "@twilio-paste/core/time-picker";
import { useUID, useUIDSeed } from "@twilio-paste/core/dist/uid-library";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { Input } from "@twilio-paste/core/input";
import { Text } from "@twilio-paste/core/text";
import { Alert } from "@twilio-paste/core/alert";
import { TextArea } from "@twilio-paste/core/textarea";
import { Label } from "@twilio-paste/core/label";
import { HelpText } from "@twilio-paste/core/help-text";
import {
	useComboboxPrimitive,
	useMultiSelectPrimitive
} from "@twilio-paste/core/combobox-primitive";
import {
	useFormPillState,
	FormPillGroup,
	FormPill
} from "@twilio-paste/core/form-pill-group";
import {
	ComboboxListbox,
	ComboboxListboxGroup,
	ComboboxListboxOption
} from "@twilio-paste/combobox";
import { Separator } from "@twilio-paste/core/separator";
import { ShowIcon } from "@twilio-paste/icons/esm/ShowIcon";
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalFooterActions,
	ModalHeader,
	ModalHeading
} from "@twilio-paste/core/modal";
import { Stack } from "@twilio-paste/core/stack";
import { SkeletonLoader } from "@twilio-paste/core/skeleton-loader";

type LocationProps = {
  state: {
    selectedServiceIds: number[];
    channelConfigs: any[];
    channelConfigInstances: any[];
  };
};

export const SetupPage: FC = () => {
	const location = useLocation() as unknown as LocationProps;

	const { toaster } = useToasterContext();
	const [loaded, setLoaded] = useState<boolean>();
	const [i, setI] = useState<number>(0);
	const [channelConfigs, setChannelConfigs] = useState<any[]>();
	const [channelConfigInstances, setChannelConfigInstances] = useState<any[]>();
	const [intakeUrlVisible, setIntakeUrlVisible] = useState<boolean>(false);
	const [emailTemplates, setEmailTemplates] = useState<Templates>();
	const [agency, setAgency] = useState<Agency>();
	const { appState } = useAppState();
	const [setupCopyFrom, setSetupCopyFrom] = useState<string>();

	const [isOpenModal, setIsOpenModal] = useState(false);
	const handleOpen = () => setIsOpenModal(true);
	const handleClose = () => setIsOpenModal(false);

	const [isOpenStateModal, setIsOpenStateModal] = useState(false);
	const handleStateOpen = () => setIsOpenStateModal(true);
	const handleStateClose = () => setIsOpenStateModal(false);

	const [isOpenNextModal, setIsOpenNextModal] = useState(false);
	const handleNextClose = () => setIsOpenNextModal(false);

	const modalHeadingID = useUID();
	const { agencyId } = useParams();

	const [selectedServiceIds, setSelectedServiceIds] = useState<number[]>();

	// Validation***
	const [validationErrorsStartTime, setValidationErrorsStartTime] = useState<
    string[]
  >(["", "", "", "", "", "", ""]);
	const [validationErrorsEndTime, setValidationErrorsEndTime] = useState<
    string[]
  >(["", "", "", "", "", "", ""]);
	const [validationErrorsLimit, setValidationErrorsLimit] = useState<string[]>([
		"",
		"",
		"",
		"",
		"",
		"",
		""
	]);
	const [phoneError, setPhoneError] = useState<string>("");
	const [pageError, setPageError] = useState<boolean>(false);
	const [emailError, setEmailError] = useState<string>("");
	const [agencyIntroError, setAgencyIntroError] = useState<string>("");
	const [logoError, setLogoError] = useState<string>("");
	const [statesError, setStatesError] = useState<string>("");
	const [destinationUrlError, setDestinationUrlError] = useState<string>("");

	//* ****

	const { listTemplates, getAgency } = useNFCCContext();

	// Bits for the state selector: ***
	const states = [
		"Alabama",
		"Alaska",
		"Arizona",
		"Arkansas",
		"California",
		"Colorado",
		"Connecticut",
		"Delaware",
		"District of Columbia",
		"Florida",
		"Georgia",
		"Guam",
		"Hawaii",
		"Idaho",
		"Illinois",
		"Indiana",
		"Iowa",
		"Kansas",
		"Kentucky",
		"Louisiana",
		"Maine",
		"Maryland",
		"Massachusetts",
		"Michigan",
		"Minnesota",
		"Mississippi",
		"Missouri",
		"Montana",
		"Nebraska",
		"Nevada",
		"New Hampshire",
		"New Jersey",
		"New Mexico",
		"New York",
		"North Carolina",
		"North Dakota",
		"Northern Mariana Islands",
		"Ohio",
		"Oklahoma",
		"Oregon",
		"Pennsylvania",
		"Puerto Rico",
		"Rhode Island",
		"South Carolina",
		"South Dakota",
		"Tennessee",
		"Texas",
		"US Virgin Islands",
		"Utah",
		"Vermont",
		"Virginia",
		"Washington",
		"West Virginia",
		"Wisconsin",
		"Wyoming"
	];
	const seed = useUIDSeed();
	const [filteredItems, setFilteredItems] = useState([...states]);

	const formPillState = useFormPillState();

	const {
		getSelectedItemProps,
		getDropdownProps,
		addSelectedItem,
		removeSelectedItem,
		selectedItems
	} = useMultiSelectPrimitive({});

	const handleSelectItemOnClick = (e: any) => {
		addSelectedItem(e);

		setFilteredItems((currentFilteredItems) =>
			currentFilteredItems.filter((item) => item !== e)
		);

		if (channelConfigInstances) {
			const newStates = channelConfigInstances[i].states.map((x: string) => x);
			newStates.push(e);
			const newCCI = {
				...(channelConfigInstances ?? [])[i],
				states: newStates
			};
			const oldCCIs: any[] = channelConfigInstances.map((x) => x);
			oldCCIs.splice(i, 1, newCCI);
			setChannelConfigInstances(oldCCIs);
		}
	};

	const handleRemoveItemOnClick = (e: any) => {
		removeSelectedItem(e);

		setFilteredItems((currentFilteredItems) =>
			[...currentFilteredItems, e].sort()
		);

		if (channelConfigInstances) {
			const newStates: string[] = (
				channelConfigInstances[i].states ?? []
			).filter((s: string) => s !== e);
			const newCCI = { ...channelConfigInstances[i], states: newStates };
			const oldCCIs: any[] = channelConfigInstances.map((x) => x);
			oldCCIs.splice(i, 1, newCCI);
			setChannelConfigInstances(oldCCIs);
		}
	};

	const {
		getComboboxProps,
		getInputProps,
		getItemProps,
		getLabelProps,
		getMenuProps,
		getToggleButtonProps,
		highlightedIndex,
		isOpen,
		selectedItem,
		selectItem
	} = useComboboxPrimitive({
		items: filteredItems,
		initialInputValue: "",
		onSelectedItemChange: ({ selectedItem: selected }) => {
			if (selected != null) {
				handleSelectItemOnClick(selected);
			}

			selectItem(null!);
		}
	});

	const inputId = seed("input-element");
	//* *****

	useEffect(() => {
		(async () => {
			try {
				setValidationErrorsEndTime(["", "", "", "", "", "", ""]);
				setValidationErrorsLimit(["", "", "", "", "", "", ""]);
				setValidationErrorsStartTime(["", "", "", "", "", "", ""]);

				const { state } = location;
				setChannelConfigs(state.channelConfigs);

				let cciState = [];

				if (state.channelConfigInstances) {
					cciState = state.channelConfigInstances;
				}

				const newChConfigInstances: any[] = [];
				let localIndex = 0;

				setSelectedServiceIds(state.selectedServiceIds);

				for (const s of state.channelConfigs) {
					if (s.phoneEnglish) {
						const existingCCIs = (cciState ?? []).filter(
							(x) => x.displayName === s.name + " - Phone - English"
						);

						if (existingCCIs.length > 0) {
							newChConfigInstances.push(existingCCIs[0]);
						} else {
							newChConfigInstances.push({
								index: localIndex,
								serviceId: s.serviceId,
								serviceName: s.name,
								category: s.category,
								displayName: s.name + " - Phone - English",
								connectionType: "Phone",
								language: "English",
								startTimes: [
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--"
								],
								endTimes: [
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--"
								],
								offline: [false, false, false, false, false, false, false],
								commsLogoUrl: "",
								destination: "",
								commsUrl: "",
								destinationIntakeUrl: "",
								commsIntroduction: "",
								connectionLimits: [0, 0, 0, 0, 0, 0, 0],
								timezone: "EST",
								states: [],
								timeOverride: [false, false, false, false, false, false, false],
								commsPhone: ""
							});
						}

						localIndex = localIndex + 1;
					}
					if (s.phoneSpanish) {
						const existingCCIs = (cciState ?? []).filter(
							(x) => x.displayName === s.name + " - Phone - Spanish"
						);

						if (existingCCIs.length > 0) {
							newChConfigInstances.push(existingCCIs[0]);
						} else {
							newChConfigInstances.push({
								index: localIndex,
								serviceId: s.serviceId,
								serviceName: s.name,
								category: s.category,
								displayName: s.name + " - Phone - Spanish",
								connectionType: "Phone",
								language: "Spanish",
								startTimes: [
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--"
								],
								endTimes: [
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--"
								],
								offline: [false, false, false, false, false, false, false],
								commsLogoUrl: "",
								connectionLimits: [0, 0, 0, 0, 0, 0, 0],
								timezone: "EST",
								destination: "",
								commsUrl: "",
								destinationIntakeUrl: "",
								commsIntroduction: "",
								states: [],
								timeOverride: [false, false, false, false, false, false, false],
								commsPhone: ""
							});
						}
						localIndex = localIndex + 1;
					}
					if (s.onlineEnglish) {
						const existingCCIs = (cciState ?? []).filter(
							(x) => x.displayName === s.name + " - Online - English"
						);

						if (existingCCIs.length > 0) {
							newChConfigInstances.push(existingCCIs[0]);
						} else {
							newChConfigInstances.push({
								index: localIndex,
								serviceId: s.serviceId,
								serviceName: s.name,
								category: s.category,
								displayName: s.name + " - Online - English",
								connectionType: "Online",
								language: "English",
								startTimes: [
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--"
								],
								endTimes: [
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--"
								],
								offline: [false, false, false, false, false, false, false],
								commsLogoUrl: "",
								connectionLimits: [0, 0, 0, 0, 0, 0, 0],
								destination: "",
								commsUrl: "",
								destinationIntakeUrl: "",
								commsIntroduction: "",
								timezone: "EST",
								states: [],
								timeOverride: [false, false, false, false, false, false, false],
								commsPhone: ""
							});
						}
						localIndex = localIndex + 1;
					}
					if (s.onlineSpanish) {
						const existingCCIs = (cciState ?? []).filter(
							(x) => x.displayName === s.name + " - Online - Spanish"
						);

						if (existingCCIs.length > 0) {
							newChConfigInstances.push(existingCCIs[0]);
						} else {
							newChConfigInstances.push({
								index: localIndex,
								serviceId: s.serviceId,
								serviceName: s.name,
								category: s.category,
								displayName: s.name + " - Online - Spanish",
								connectionType: "Online",
								language: "Spanish",
								startTimes: [
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--"
								],
								endTimes: [
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--",
									"--:--:--"
								],
								offline: [false, false, false, false, false, false, false],
								commsLogoUrl: "",
								connectionLimits: [0, 0, 0, 0, 0, 0, 0],
								destination: "",
								commsUrl: "",
								destinationIntakeUrl: "",
								commsIntroduction: "",
								timezone: "EST",
								states: [],
								timeOverride: [false, false, false, false, false, false, false],
								commsPhone: ""
							});
						}
						localIndex = localIndex + 1;
					}
				}
				setChannelConfigInstances(newChConfigInstances);

				const t = await listTemplates();
				setEmailTemplates(t);

				if (agencyId) {
					const ag = await getAgency(parseInt(agencyId));
					setAgency(ag);
				} else {
					const ag = await getAgency(appState.agencyId);
					setAgency(ag);
				}

				setLoaded(true);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve configuration data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (channelConfigInstances) {
			const index: number = parseInt(target.name.split("#")[2]);
			const weekday: string = target.name.split("#")[0];
			const fieldName: string = target.name.split("#")[1];

			if (fieldName === "startTimes") {
				const v = validationErrorsStartTime.map((x) => x);
				v.splice(index, 1, "");
				setValidationErrorsStartTime(v);

				const t: boolean[] = channelConfigInstances[i].timeOverride;
				t.splice(index, 1, false);
				const times = channelConfigInstances[i].startTimes;
				times.splice(index, 1, target.value);
				const newCCI = {
					...channelConfigInstances[i],
					["timeOverride"]: t,
					[fieldName]: times
				};
				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);

				// validate
				const endTime = newCCI.endTimes[index];
				const startDateTime = new Date();
				startDateTime.setHours(parseInt(target.value.split(":")[0]));
				startDateTime.setMinutes(parseInt(target.value.split(":")[1]));

				if (endTime !== "--:--" && endTime !== "--:--:--") {
					const endDateTime = new Date();
					endDateTime.setHours(parseInt(endTime.split(":")[0]));
					endDateTime.setMinutes(parseInt(endTime.split(":")[1]));

					if (startDateTime > endDateTime) {
						const v = validationErrorsStartTime.map((x) => x);
						v.splice(index, 1, "Start time cannot be after end time");
						setValidationErrorsStartTime(v);
					} else {
						const v = validationErrorsStartTime.map((x) => x);
						v.splice(index, 1, "");
						setValidationErrorsStartTime(v);
					}
				}
			} else if (fieldName === "endTimes") {
				const v = validationErrorsEndTime.map((x) => x);
				v.splice(index, 1, "");
				setValidationErrorsEndTime(v);

				const t: boolean[] = channelConfigInstances[i].timeOverride;
				t.splice(index, 1, false);
				const times = channelConfigInstances[i].endTimes;
				times.splice(index, 1, target.value);
				const newCCI = {
					...channelConfigInstances[i],
					["timeOverride"]: t,
					[fieldName]: times
				};
				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);

				// validate
				const startTime = newCCI.startTimes[index];
				const endDateTime = new Date();
				endDateTime.setHours(parseInt(target.value.split(":")[0]));
				endDateTime.setMinutes(parseInt(target.value.split(":")[1]));

				if (startTime !== "--:--" && startTime !== "--:--:--") {
					const startDateTime = new Date();
					startDateTime.setHours(parseInt(startTime.split(":")[0]));
					startDateTime.setMinutes(parseInt(startTime.split(":")[1]));

					if (startDateTime > endDateTime) {
						const v = validationErrorsEndTime.map((x) => x);
						v.splice(index, 1, "End time cannot be before start time");
						setValidationErrorsEndTime(v);
					} else {
						const v = validationErrorsEndTime.map((x) => x);
						v.splice(index, 1, "");
						setValidationErrorsEndTime(v);
					}
				}
			} else if (fieldName === "connectionLimits") {
				const limits = channelConfigInstances[i].connectionLimits;
				limits.splice(index, 1, target.value);
				const newCCI = { ...channelConfigInstances[i], [fieldName]: limits };
				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);

				if (
					isNaN(parseInt(target.value)) ||
          parseInt(target.value).toString().length !== target.value.length
				) {
					const v = validationErrorsLimit.map((x) => x);
					v.splice(index, 1, "Connection limit must be a number");
					setValidationErrorsLimit(v);
				} else if (parseInt(target.value) < 0) {
					const v = validationErrorsLimit.map((x) => x);
					v.splice(index, 1, "Connection limit must be a positive number");
					setValidationErrorsLimit(v);
				} else if (parseInt(target.value) === 0) {
					const v = validationErrorsLimit.map((x) => x);
					v.splice(
						index,
						1,
						"Connection limit is set to 0, this means you will not get any connections on this day"
					);
					setValidationErrorsLimit(v);
				} else {
					const v = validationErrorsLimit.map((x) => x);
					v.splice(index, 1, "");
					setValidationErrorsLimit(v);
				}
			}
		}
	};

	const handleTimezoneChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (channelConfigInstances) {
			const newCCI = { ...channelConfigInstances[i], timezone: target.value };
			const oldCCIs: any[] = channelConfigInstances.map((x) => x);
			oldCCIs.splice(i, 1, newCCI);
			setChannelConfigInstances(oldCCIs);
		}
	};

	const handleEnableDisable24hours = (e: any) => {
		if (channelConfigInstances) {
			const index: number = parseInt(e.target.name.split("#")[2]);
			const weekday: string = e.target.name.split("#")[0];

			const v = validationErrorsEndTime.map((x) => x);
			v.splice(index, 1, "");
			setValidationErrorsEndTime(v);

			const v1 = validationErrorsStartTime.map((x) => x);
			v1.splice(index, 1, "");
			setValidationErrorsStartTime(v1);

			if (e.target.checked) {
				const t: boolean[] = channelConfigInstances[i].timeOverride;
				t.splice(index, 1, true);
				const t1: boolean[] = channelConfigInstances[i].offline;
				t1.splice(index, 1, false);
				const sTimes = channelConfigInstances[i].startTimes;
				sTimes.splice(index, 1, "00:00:00");
				const eTimes = channelConfigInstances[i].endTimes;
				eTimes.splice(index, 1, "23:59:00");
				const newCCI = {
					...channelConfigInstances[i],
					["startTimes"]: sTimes,
					["endTimes"]: eTimes,
					["timeOverride"]: t,
					offline: t1
				};
				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);
			} else {
				const t: boolean[] = channelConfigInstances[i].timeOverride;
				t.splice(index, 1, false);
				const t1: boolean[] = channelConfigInstances[i].offline;
				t1.splice(index, 1, false);
				const sTimes = channelConfigInstances[i].startTimes;
				sTimes.splice(index, 1, "--:--:--");
				const eTimes = channelConfigInstances[i].endTimes;
				eTimes.splice(index, 1, "-:--:--");
				const newCCI = {
					...channelConfigInstances[i],
					["startTimes"]: sTimes,
					["endTimes"]: eTimes,
					["timeOverride"]: t,
					offline: t1
				};
				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);
			}
		}
	};

	const handleEnableDisableOffline = (e: any) => {
		if (channelConfigInstances) {
			const index: number = parseInt(e.target.name.split("#")[2]);
			const weekday: string = e.target.name.split("#")[0];

			const v2 = validationErrorsEndTime.map((x) => x);
			v2.splice(index, 1, "");
			setValidationErrorsEndTime(v2);

			const v1 = validationErrorsStartTime.map((x) => x);
			v1.splice(index, 1, "");
			setValidationErrorsStartTime(v1);

			if (e.target.checked) {
				const t: boolean[] = channelConfigInstances[i].timeOverride;
				t.splice(index, 1, false);
				const o: boolean[] = channelConfigInstances[i].offline;
				o.splice(index, 1, true);
				const c = channelConfigInstances[i].connectionLimits;
				c.splice(index, 1, 0);

				const sTimes = channelConfigInstances[i].startTimes;
				sTimes.splice(index, 1, "00:00:00");
				const eTimes = channelConfigInstances[i].endTimes;
				eTimes.splice(index, 1, "00:00:00");
				const newCCI = {
					...channelConfigInstances[i],
					["startTimes"]: sTimes,
					["endTimes"]: eTimes,
					["timeOverride"]: t,
					["offline"]: o,
					connectionLimits: c
				};
				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);
			} else {
				const t: boolean[] = channelConfigInstances[i].timeOverride;
				t.splice(index, 1, false);
				const o: boolean[] = channelConfigInstances[i].offline;
				o.splice(index, 1, false);
				const sTimes = channelConfigInstances[i].startTimes;
				sTimes.splice(index, 1, "--:--:--");
				const eTimes = channelConfigInstances[i].endTimes;
				eTimes.splice(index, 1, "--:--:--");
				const c = channelConfigInstances[i].connectionLimits;
				c.splice(index, 1, null);
				const newCCI = {
					...channelConfigInstances[i],
					["startTimes"]: sTimes,
					["endTimes"]: eTimes,
					["timeOverride"]: t,
					["offline"]: o,
					connectionLimits: c
				};
				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);
			}

			const v = validationErrorsLimit.map((x) => x);
			v.splice(index, 1, "");
			setValidationErrorsLimit(v);
		}
	};

	const handleTextChange = (e: any) => {
		if (channelConfigInstances) {
			const newCCI = {
				...channelConfigInstances[i],
				[e.target.name]: e.target.value
			};
			const oldCCIs: any[] = channelConfigInstances.map((x) => x);
			oldCCIs.splice(i, 1, newCCI);
			setChannelConfigInstances(oldCCIs);
		}
	};

	const validate = () => {
		// validate
		if (channelConfigInstances) {
			let isError = false;
			const newStartTimeErrors = [];
			const newEndTimeErrors = [];
			for (const x in channelConfigInstances[i].startTimes) {
				const startTime = channelConfigInstances[i].startTimes[x];
				const endTime = channelConfigInstances[i].endTimes[x];
				let timeError = false;

				// check if unset
				if (startTime === "--:--" || startTime === "--:--:--") {
					newStartTimeErrors.push("Start time cannot be blank");
					timeError = true;
					isError = true;
				} else {
					newStartTimeErrors.push("");
				}

				if (endTime === "--:--" || endTime === "--:--:--") {
					newEndTimeErrors.push("End time cannot be blank");
					timeError = true;
					isError = true;
				}

				if (!timeError) {
					const startDt = new Date();
					startDt.setHours(parseInt(startTime.split(":")[0]));
					startDt.setMinutes(parseInt(startTime.split(":")[1]));

					const endDt = new Date();
					endDt.setHours(parseInt(endTime.split(":")[0]));
					endDt.setMinutes(parseInt(endTime.split(":")[1]));

					if (endDt < startDt) {
						newEndTimeErrors.push("End time cannot be before start time");
						isError = true;
					} else {
						newEndTimeErrors.push("");
					}
				}
			}
			setValidationErrorsStartTime(newStartTimeErrors);
			setValidationErrorsEndTime(newEndTimeErrors);

			const limitVal = [];
			for (const l in channelConfigInstances[i].connectionLimits) {
				if (!channelConfigInstances[i].offline[l]) {
					if (parseInt(channelConfigInstances[i].connectionLimits[l]) === 0) {
						limitVal.push(
							"Connection limit is set to 0, this means you will not get any connections on this day"
						);
						isError = true;
					} else if (
						isNaN(parseInt(channelConfigInstances[i].connectionLimits[l])) ||
            parseInt(channelConfigInstances[i].connectionLimits[l]).toString().length !==
              channelConfigInstances[i].connectionLimits[l].toString().length
					) {
						limitVal.push("Connection limit must be a number");
						isError = true;
					} else if (
						parseInt(channelConfigInstances[i].connectionLimits[l]) < 0
					) {
						limitVal.push("Connection limit must be a positive number");
						isError = true;
					} else {
						limitVal.push("");
					}
				} else {
					limitVal.push("");
				}
			}

			setValidationErrorsLimit(limitVal);

			setPhoneError("");

			const dest = channelConfigInstances[i].destination
				.replace("+1", "")
				.replaceAll("-", "");

			if (
				channelConfigInstances[i].connectionType === "Phone" &&
        (dest === undefined || dest === "")
			) {
				setPhoneError("Phone number cannot be blank");
				isError = true;
			} else if (
				channelConfigInstances[i].connectionType === "Phone" &&
        !["800", "888", "877", "866", "855", "844", "833"].includes(dest.substring(0, 3))
			) {
				if(agencyId)
				{
					if(parseInt(agencyId) != 298)
					{
						setPhoneError("Phone number must be toll-free");
						isError = true;
					}
				}
				
			} else if (
				channelConfigInstances[i].connectionType === "Phone" &&
        parseInt(dest).toString().length !==
          dest.length - parseInt(dest.match(/-/g) || [].length)
			) {
				setPhoneError(
					"Phone number cannot contain text characters (exc. dashes)"
				);
				isError = true;
			} else if (
				channelConfigInstances[i].connectionType === "Phone" &&
        dest.length != 10
			) {
				setPhoneError("Phone number must be exactly 10 characters");
				isError = true;
			}

			setEmailError("");
			setLogoError("");
			setAgencyIntroError("");

			if (
				channelConfigInstances[i].connectionType === "Online" &&
        (channelConfigInstances[i].destinationEmail === undefined ||
          channelConfigInstances[i].destinationEmail === "")
			) {
				setEmailError("Email address cannot be blank");
				isError = true;
			}
			if (
				channelConfigInstances[i].connectionType === "Online" &&
        (channelConfigInstances[i].commsIntroduction === undefined ||
          channelConfigInstances[i].commsIntroduction === "")
			) {
				setAgencyIntroError("Agency introduction cannot be blank");
				isError = true;
			}
			if (
				channelConfigInstances[i].connectionType === "Online" &&
        (channelConfigInstances[i].commsLogoUrl === undefined ||
          channelConfigInstances[i].commsLogoUrl === "")
			) {
				setLogoError("Logo cannot be blank");
				isError = true;
			} else if (
				channelConfigInstances[i].connectionType === "Online" &&
        channelConfigInstances[i].commsLogoUrl.substring(0, 4) !== "http"
			) {
				setLogoError("Logo must be a link to an image");
				isError = true;
			}

			if (channelConfigInstances[i].states.length === 0) {
				setStatesError("At least one state must be selected");
				isError = true;
			} else {
				setStatesError("");
			}

			if (
				channelConfigInstances[i].connectionType === "Online" &&
				channelConfigInstances[i].destinationIntakeUrl.length >= 1 && 
				channelConfigInstances[i].destinationIntakeUrl.substring(0,4) !== 'http'
			)
			{
				setDestinationUrlError("Intake url must start with http or https")
				isError = true;
			}
			else{
				setDestinationUrlError("")
			}

			return isError;
		}
	};

	const increment = () => {
		setValidationErrorsStartTime(["", "", "", "", "", "", ""]);
		setValidationErrorsEndTime(["", "", "", "", "", "", ""]);
		setValidationErrorsLimit(["", "", "", "", "", "", ""]);

		const isError = validate();

		if (channelConfigInstances) {
			if (!isError) {
				// no errors!
				const newI = i + 1;
				setI(newI);

				setPageError(false);

				const s = states.filter(
					(x) => !channelConfigInstances[newI].states.includes(x)
				);
				setFilteredItems(s);

				if (channelConfigInstances[newI].destinationIntakeUrl) {
					setIntakeUrlVisible(true);
				} else {
					setIntakeUrlVisible(false);
				}

				setSetupCopyFrom("");

				window.scrollTo(0, 0);
			} else {
				setPageError(true);
				window.scrollTo(0, 0);
			}
		}
	};

	const handleNextOpen = () => {
		const isError = validate();
		// if no validation errors
		if (!isError) {
			setIsOpenNextModal(true);
		}
	};

	const getNextLink = () => {
		if (appState.role === "nfcc_admin") {
			const p = generatePath(
				"/agencies/:agencyId/start/selection/channel/setup/review",
				{ agencyId: agencyId ?? '0' }
			);
			return p;
		} else {
			const p = generatePath("/start/selection/channel/setup/review");
			return p;
		}
	};

	const getPreviousLink = () => {
		if (appState.role === "nfcc_admin") {
			const p = generatePath("/agencies/:agencyId/start/selection/channel/", {
				agencyId: agencyId ?? '0'
			});
			return p;
		} else {
			const p = generatePath("/start/selection/channel/");
			return p;
		}
	};

	const getTemplate = (language: string) => {
		if (emailTemplates) {
			for (const t of emailTemplates.templates ?? []) {
				if (t.name === "client_" + language.toLowerCase()) {
					const { html } = t;

					// replace template tags
					if (html && channelConfigInstances && agency) {
						const agencyName = agency.name;
						const agencyPhone = channelConfigInstances[i].commsPhone ?? "";
						const agencyWebsite = channelConfigInstances[i].commsUrl ?? "";
						const agencyIntro =
              channelConfigInstances[i].commsIntroduction ?? "";
						const commsLogoUrl = channelConfigInstances[i].commsLogoUrl ?? "";

						return html
							.replaceAll("{{agency_name}}", agencyName ?? "")
							.replaceAll("{{agency_phone}}", agencyPhone)
							.replaceAll("{{agency_website}}", agencyWebsite)
							.replaceAll("{{agency_blurb}}", agencyIntro)
							.replaceAll("{{agency_logo}}", commsLogoUrl);
					}
				}
			}
			return "<div/>";
		}
		return "<div/>";
	};

	const decrement = () => {
		setValidationErrorsStartTime(["", "", "", "", "", "", ""]);
		setValidationErrorsEndTime(["", "", "", "", "", "", ""]);
		setValidationErrorsLimit(["", "", "", "", "", "", ""]);

		const newI = i - 1;
		setI(newI);

		if (channelConfigInstances) {
			const s = states.filter(
				(x) => !channelConfigInstances[newI].states.includes(x)
			);
			setFilteredItems(s);
			setSetupCopyFrom("");

			if (channelConfigInstances[newI].destinationIntakeUrl) {
				setIntakeUrlVisible(true);
			} else {
				setIntakeUrlVisible(false);
			}
		}

		window.scrollTo(0, 0);
	};

	const handleDefault = (e: any) => {
		setSetupCopyFrom(e.target.value);

		if (channelConfigInstances) {
			if (e.target.value === "") {
				// reset everything
				const oldCCI = { ...channelConfigInstances[i] };

				const newCCI = {
					index: i,
					displayName: oldCCI.displayName,
					serviceId: oldCCI.serviceId,
					serviceName: oldCCI.serviceName,
					connectionType: oldCCI.connectionType,
					language: oldCCI.language,
					category: oldCCI.category,

					startTimes: [
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--"
					],
					endTimes: [
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--"
					],
					offline: [false, false, false, false, false, false, false],
					commsLogoUrl: "",
					destination: "",
					commsUrl: "",
					destinationIntakeUrl: "",
					commsIntroduction: "",
					connectionLimits: [0, 0, 0, 0, 0, 0, 0],
					timezone: "EST",
					states: [],
					timeOverride: [false, false, false, false, false, false, false],
					commsPhone: ""
				};
				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);
			} else {
				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				const oldCCI = { ...oldCCIs[i] };
				const copyCCI = { ...oldCCIs[e.target.value] };

				const limits = copyCCI.connectionLimits.map((x: string) => x);
				const startTimes = copyCCI.startTimes.map((x: string) => x);
				const endTimes = copyCCI.endTimes.map((x: string) => x);
				const offline = copyCCI.offline.map((x: boolean) => x);
				const timeOverride = copyCCI.timeOverride.map((x: boolean) => x);

				let newCCI: any;
				if (oldCCI.connectionType != copyCCI.connectionType) {
					newCCI = {
						...copyCCI,
						index: oldCCI.index,
						displayName: oldCCI.displayName,
						serviceId: oldCCI.serviceId,
						serviceName: oldCCI.serviceName,
						connectionType: oldCCI.connectionType,
						language: oldCCI.language,
						connectionLimits: limits,
						startTimes: startTimes,
						endTimes: endTimes,
						offline: offline,
						timeOverride: timeOverride,
						category: oldCCI.category,
						destination: "",
						destinationEmail: "",
						commsUrl: "",
						commsLogoUrl: "",
						commsIntroduction: "",
						commsPhone: ""
					};
				} else {
					newCCI = {
						...copyCCI,
						index: oldCCI.index,
						displayName: oldCCI.displayName,
						serviceId: oldCCI.serviceId,
						serviceName: oldCCI.serviceName,
						connectionType: oldCCI.connectionType,
						language: oldCCI.language,
						connectionLimits: limits,
						startTimes: startTimes,
						endTimes: endTimes,
						offline: offline,
						timeOverride: timeOverride,
						category: oldCCI.category
					};
				}

				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);

				if (newCCI.destinationIntakeUrl.length > 0) {
					setIntakeUrlVisible(true);
				} else {
					setIntakeUrlVisible(false);
				}

				const s = states.filter((x) => !newCCI.states.includes(x));
				setFilteredItems(s);
			}

			// clear any errors
			setEmailError("");
			setLogoError("");
			setAgencyIntroError("");
			setPhoneError("");

			setValidationErrorsEndTime(["", "", "", "", "", "", ""]);
			setValidationErrorsLimit(["", "", "", "", "", "", ""]);
			setValidationErrorsStartTime(["", "", "", "", "", "", ""]);
		}
	};

	const setIntakeUrlVisibility = (e: any) => {
		setIntakeUrlVisible(e.target.checked);
	};

	const setAllChecked = (checked: boolean) => {
		if (checked) {
			if (channelConfigInstances) {
				const newCCI = { ...channelConfigInstances[i], states: states };
				setFilteredItems([]);

				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);
			}
		} else {
			if (channelConfigInstances) {
				const newCCI = { ...channelConfigInstances[i], states: [] };
				setFilteredItems(states);
				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);
			}
		}
	};

	const setAllChecked24 = (checked: boolean) => {
		if (channelConfigInstances) {
			if (checked) {
				setValidationErrorsEndTime(["", "", "", "", "", "", ""]);

				setValidationErrorsStartTime(["", "", "", "", "", "", ""]);
				const override: boolean[] = [true, true, true, true, true, true, true];
				const offline: boolean[] = [
					false,
					false,
					false,
					false,
					false,
					false,
					false
				];

				const sTimes = [
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00"
				];
				const eTimes = [
					"23:59:00",
					"23:59:00",
					"23:59:00",
					"23:59:00",
					"23:59:00",
					"23:59:00",
					"23:59:00"
				];
				const newCCI = {
					...channelConfigInstances[i],
					["startTimes"]: sTimes,
					["endTimes"]: eTimes,
					["timeOverride"]: override,
					offline: offline
				};
				const oldCCIs: any[] = channelConfigInstances.map((x) => x);
				oldCCIs.splice(i, 1, newCCI);
				setChannelConfigInstances(oldCCIs);
			} else {
				if (channelConfigInstances) {
					const override: boolean[] = [
						false,
						false,
						false,
						false,
						false,
						false,
						false
					];
					const offline: boolean[] = [
						false,
						false,
						false,
						false,
						false,
						false,
						false
					];

					const sTimes = [
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--"
					];
					const eTimes = [
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--",
						"--:--:--"
					];
					const newCCI = {
						...channelConfigInstances[i],
						["startTimes"]: sTimes,
						["endTimes"]: eTimes,
						["timeOverride"]: override,
						offline: offline
					};
					const oldCCIs: any[] = channelConfigInstances.map((x) => x);
					oldCCIs.splice(i, 1, newCCI);
					setChannelConfigInstances(oldCCIs);
				}
			}
		}
	};

	const handleStateCloseConfirm = () => {
		if (channelConfigInstances) {
			if (i === channelConfigInstances.length - 1) {
				setIsOpenNextModal(true);
			}
		} else {
			increment();
		}

		setIsOpenStateModal(false);
	};

	return (
		<>
			<StepperStage3 />
			{pageError && (
				<Box paddingTop="space60">
					<Alert variant="error">
						<Text as="span">
              Validation errors have been found on the page, please check these
              before continuing
						</Text>
					</Alert>
				</Box>
			)}

			<Box marginY="space60" display="flex" flexDirection="row">
				<Box width="80%">
					{channelConfigInstances &&
            (channelConfigInstances ?? []).filter((s) => s.index === i).map((s: any, index: number) => (<Heading as="h1" variant="heading10" key="heading">{s.displayName}</Heading>))}
				</Box>

				<Box width="20%">
					<Select
						id="Copysetup"
						onChange={handleDefault}
						value={setupCopyFrom}
						aria-label="Copy setup from another service"
					>
						<Option value="">Copy setup from</Option>
						{channelConfigInstances &&
              (channelConfigInstances ?? []).filter((s) => s.index !== i).map((s: any, index: number) => (<Option value={s.index} key={s.displayName}>{s.displayName}</Option>))}
					</Select>
				</Box>
			</Box>

			<Box>
				<Heading as="h2" variant="heading20">
					{i + 1 + " of " + (channelConfigInstances ?? []).length + " services"}
				</Heading>
			</Box>

			<Box marginY="space60">
				<Heading as="h3" variant="heading30">
          Step 1
				</Heading>
			</Box>

			{loaded && channelConfigInstances && states ? (
				<Box display="flex" width="100%" justifyContent="space-between">
					<Box marginY="space60" position="relative" width="50%">
						<Label htmlFor={inputId} {...getLabelProps()}>
              Which states would you like to receive leads from?
						</Label>
						<Checkbox
							key="select_all"
							id="select_all"
							checked={
								channelConfigInstances[i].states.length === states.length
							}
							indeterminate={
								channelConfigInstances[i].states.length !== states.length &&
                channelConfigInstances[i].states.length !== 0
							}
							onChange={(e) => setAllChecked(e.target.checked)}
						>
              Select all
						</Checkbox>
						<Box
							{...getComboboxProps({ role: "combobox" })}
							aria-label="States"
						>
							<Input
								id={inputId}
								type="text"
								{...getInputProps({
									...getDropdownProps({
										preventKeyAction: isOpen,
										...getToggleButtonProps({ tabIndex: 0 })
									})
								})}
								value={selectedItem || ""}
							/>
						</Box>
						<ComboboxListbox hidden={!isOpen} {...getMenuProps()}>
							<ComboboxListboxGroup>
								{filteredItems.map((filteredItem, index) => (
									<ComboboxListboxOption
										highlighted={highlightedIndex === index}
										variant="default"
										{...getItemProps({
											item: filteredItem,
											index,
											key: seed("filtered-item-" + filteredItem)
										})}
										key={"filtered-item-" + filteredItem}
									>
										{filteredItem}
									</ComboboxListboxOption>
								))}
							</ComboboxListboxGroup>
						</ComboboxListbox>
						{statesError.length > 0 && (
							<HelpText variant="error">{statesError}</HelpText>
						)}
					</Box>

					<Box
						padding="space60"
						width="40%"
						display="flex"
						flexDirection="column"
						justifyContent="space-around"
						borderColor="colorBorder"
						borderWidth="borderWidth10"
						borderStyle="solid"
					>
						<Heading as="h3" variant="heading30">
              Selected States
						</Heading>
						<Separator orientation="horizontal" verticalSpacing="space60" />
						<Box marginY="space60">
							<FormPillGroup
								{...formPillState}
								aria-label="Selected States"
								role="list"
							>
								{(channelConfigInstances ?? [{ states: [] }])[i].states.map(
									(item: string, index: number) => {
										return (
											<FormPill
												{...getSelectedItemProps({
													selectedItem,
													index,
													key: "selected-item-" + item
												})}
												key={"selected-item-" + item}
												tabIndex={null}
												{...formPillState}
												onDismiss={() => handleRemoveItemOnClick(item)}
												role="listitem"
												aria-label={item}
											>
												{item}
											</FormPill>
										);
									}
								)}
							</FormPillGroup>
						</Box>
					</Box>
				</Box>
			) : (
				<Box width="100%">
					<Stack orientation="vertical" spacing="space60">
						<SkeletonLoader />
					</Stack>
				</Box>
			)}

			{loaded && channelConfigInstances && (
				<Box marginY="space60">
					<Heading as="h3" variant="heading30">
            Step 2
					</Heading>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
					>
						<Text as="p" lineHeight="lineHeight40">
              When do you want to receive connections?
						</Text>
						<Box width="20%">
							<Select
								id="timezone"
								name="timezone"
								onChange={handleTimezoneChange}
								defaultValue={(channelConfigInstances ?? [])[i].timezone}
								aria-label="Select timezone"
							>
								<Option value="EST">Eastern Standard Time</Option>
								<Option value="CST">Central Time Zone</Option>
								<Option value="MST">Mountain Time Zone</Option>
								<Option value="PST">Pacific Time Zone</Option>
								<Option value="HST">Hawaiian Time Zone</Option>
								<Option value="AKST">Alaskan Time Zone</Option>
							</Select>
						</Box>
					</Box>
				</Box>
			)}

			{loaded &&
        validationErrorsStartTime &&
        validationErrorsEndTime &&
        validationErrorsLimit &&
        channelConfigInstances && (
				<Box marginY="space60">
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="flex-end"
						alignItems="flex-end"
						marginY="space60"
					>
						<Checkbox
							key="select_all_24h"
							id="select_all_24h"
							checked={
								!channelConfigInstances[i].timeOverride.includes(false)
							}
							indeterminate={
								channelConfigInstances[i].timeOverride.includes(false) &&
                  channelConfigInstances[i].timeOverride.includes(true)
							}
							onChange={(e) => setAllChecked24(e.target.checked)}
						>
                Set all to 24 hours
						</Checkbox>
					</Box>
					<Table>
						<THead>
							<Tr>
								<Th>Days</Th>
								<Th width="15vw">From</Th>
								<Th width="15vw">To</Th>
								<Th width="5vw">24 Hours</Th>
								<Th width="5vw">Offline</Th>
								<Th width="15vw">Daily Connection Limit</Th>
							</Tr>
						</THead>
						<TBody>
							<Tr>
								<Td>Monday</Td>
								<Td>
									<TimePicker
										id="monday#startTime"
										name="monday#startTimes#0"
										value={(channelConfigInstances ?? [])[i].startTimes[0]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[0]}
										hasError={validationErrorsStartTime[0].length > 0}
									/>
									{validationErrorsStartTime[0].length > 0 && (
										<HelpText variant="error">
											{validationErrorsStartTime[0]}
										</HelpText>
									)}
								</Td>
								<Td>
									<TimePicker
										id="monday#endTime"
										name="monday#endTimes#0"
										value={(channelConfigInstances ?? [])[i].endTimes[0]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[0]}
										hasError={validationErrorsEndTime[0].length > 0}
									/>
									{validationErrorsEndTime[0].length > 0 && (
										<HelpText variant="error">
											{validationErrorsEndTime[0]}
										</HelpText>
									)}
								</Td>
								<Td>
									<Checkbox
										key="monday#override#0"
										id="monday#override#0"
										checked={
											(channelConfigInstances ?? [])[i].timeOverride[0]
										}
										value="monday#override#0"
										name="monday#override#0"
										aria-label="Monday 24 hours"
										onClick={(e) => handleEnableDisable24hours(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Checkbox
										key="monday#offline#0"
										id="monday#offline#0"
										checked={(channelConfigInstances ?? [])[i].offline[0]}
										value="monday#offline#0"
										name="monday#offline#0"
										aria-label="Monday offline"
										onClick={(e) => handleEnableDisableOffline(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Input
										type="number"
										id="monday#connectionLimits#0"
										name="monday#connectionLimits#0"
										value={
											(channelConfigInstances ?? [])[i].connectionLimits[0]
										}
										onChange={handleChange}
										disabled={(channelConfigInstances ?? [])[i].offline[0]}
										hasError={validationErrorsLimit[0].length > 0}
										aria-label="Monday daily connection limit"
									/>
									{validationErrorsLimit[0].length > 0 && (
										<HelpText variant="error">
											{validationErrorsLimit[0]}
										</HelpText>
									)}
								</Td>
							</Tr>
							<Tr>
								<Td>Tuesday</Td>
								<Td>
									<TimePicker
										id="tuesday#startTime"
										name="tuesday#startTimes#1"
										value={(channelConfigInstances ?? [])[i].startTimes[1]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[1]}
										hasError={validationErrorsStartTime[1].length > 0}
									/>
									{validationErrorsStartTime[1].length > 0 && (
										<HelpText variant="error">
											{validationErrorsStartTime[1]}
										</HelpText>
									)}
								</Td>
								<Td>
									<TimePicker
										id="tuesday#endTime"
										name="tuesday#endTimes#1"
										value={(channelConfigInstances ?? [])[i].endTimes[1]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[1]}
										hasError={validationErrorsEndTime[1].length > 0}
									/>
									{validationErrorsEndTime[1].length > 0 && (
										<HelpText variant="error">
											{validationErrorsEndTime[1]}
										</HelpText>
									)}
								</Td>
								<Td>
									<Checkbox
										key="tuesday#override#1"
										id="tuesday#override#1"
										checked={
											(channelConfigInstances ?? [])[i].timeOverride[1]
										}
										value="tuesday#override#1"
										name="tuesday#override#1"
										aria-label="Tuesday 24 hours"
										onClick={(e) => handleEnableDisable24hours(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Checkbox
										key="tuesday#offline#1"
										id="tuesday#offline#1"
										checked={(channelConfigInstances ?? [])[i].offline[1]}
										value="tuesday#offline#1"
										name="tuesday#offline#1"
										aria-label="Tuesday offline"
										onClick={(e) => handleEnableDisableOffline(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Input
										type="number"
										id="tuesday#connectionLimits#1"
										name="tuesday#connectionLimits#1"
										onChange={handleChange}
										value={
											(channelConfigInstances ?? [])[i].connectionLimits[1]
										}
										disabled={(channelConfigInstances ?? [])[i].offline[1]}
										hasError={validationErrorsLimit[1].length > 0}
										aria-label="Tuesday daily connection limit"
									/>
									{validationErrorsLimit[1].length > 0 && (
										<HelpText variant="error">
											{validationErrorsLimit[1]}
										</HelpText>
									)}
								</Td>
							</Tr>
							<Tr>
								<Td>Wednesday</Td>
								<Td>
									<TimePicker
										id="wednesday#startTime"
										name="wednesday#startTimes#2"
										value={(channelConfigInstances ?? [])[i].startTimes[2]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[2]}
										hasError={validationErrorsStartTime[2].length > 0}
									/>
									{validationErrorsStartTime[2].length > 0 && (
										<HelpText variant="error">
											{validationErrorsStartTime[2]}
										</HelpText>
									)}
								</Td>
								<Td>
									<TimePicker
										id="wednesday#endTime"
										name="wednesday#endTimes#2"
										value={(channelConfigInstances ?? [])[i].endTimes[2]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[2]}
										hasError={validationErrorsEndTime[2].length > 0}
									/>
									{validationErrorsEndTime[2].length > 0 && (
										<HelpText variant="error">
											{validationErrorsEndTime[2]}
										</HelpText>
									)}
								</Td>
								<Td>
									<Checkbox
										key="wednesday#override#2"
										id="wednesday#override#2"
										checked={
											(channelConfigInstances ?? [])[i].timeOverride[2]
										}
										value="wednesday#override#2"
										name="wednesday#override#2"
										aria-label="Wednesday 24 hours"
										onClick={(e) => handleEnableDisable24hours(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Checkbox
										key="wednesday#offline#2"
										id="wednesday#offline#2"
										checked={(channelConfigInstances ?? [])[i].offline[2]}
										value="wednesday#offline#2"
										name="wednesday#offline#2"
										aria-label="Wednesday offline"
										onClick={(e) => handleEnableDisableOffline(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Input
										type="number"
										id="wednesday#connectionLimits#2"
										name="wednesday#connectionLimits#2"
										value={
											(channelConfigInstances ?? [])[i].connectionLimits[2]
										}
										onChange={handleChange}
										disabled={(channelConfigInstances ?? [])[i].offline[2]}
										hasError={validationErrorsLimit[2].length > 0}
										aria-label="Wednesday daily connection limit"
									/>
									{validationErrorsLimit[2].length > 0 && (
										<HelpText variant="error">
											{validationErrorsLimit[2]}
										</HelpText>
									)}
								</Td>
							</Tr>
							<Tr>
								<Td>Thursday</Td>
								<Td>
									<TimePicker
										id="thursday#startTime"
										name="thursday#startTimes#3"
										value={(channelConfigInstances ?? [])[i].startTimes[3]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[3]}
										hasError={validationErrorsStartTime[3].length > 0}
									/>
									{validationErrorsStartTime[3].length > 0 && (
										<HelpText variant="error">
											{validationErrorsStartTime[3]}
										</HelpText>
									)}
								</Td>
								<Td>
									<TimePicker
										id="thursday#endTime"
										name="thursday#endTimes#3"
										value={(channelConfigInstances ?? [])[i].endTimes[3]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[3]}
										hasError={validationErrorsEndTime[3].length > 0}
									/>
									{validationErrorsEndTime[3].length > 0 && (
										<HelpText variant="error">
											{validationErrorsEndTime[3]}
										</HelpText>
									)}
								</Td>
								<Td>
									<Checkbox
										key="thursday#override#3"
										id="thursday#override#3"
										checked={
											(channelConfigInstances ?? [])[i].timeOverride[3]
										}
										value="thursday#override#3"
										name="thursday#override#3"
										aria-label="Thursday 24 hours"
										onClick={(e) => handleEnableDisable24hours(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Checkbox
										key="thursday#offline#3"
										id="thursday#offline#3"
										checked={(channelConfigInstances ?? [])[i].offline[3]}
										value="thursday#offline#3"
										name="thursday#offline#3"
										aria-label="Thursday offline"
										onClick={(e) => handleEnableDisableOffline(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Input
										type="number"
										id="thursday#connectionLimits#3"
										name="thursday#connectionLimits#3"
										value={
											(channelConfigInstances ?? [])[i].connectionLimits[3]
										}
										onChange={handleChange}
										disabled={(channelConfigInstances ?? [])[i].offline[3]}
										hasError={validationErrorsLimit[3].length > 0}
										aria-label="Thursday daily connection limit"
									/>
									{validationErrorsLimit[3].length > 0 && (
										<HelpText variant="error">
											{validationErrorsLimit[3]}
										</HelpText>
									)}
								</Td>
							</Tr>
							<Tr>
								<Td>Friday</Td>
								<Td>
									<TimePicker
										id="friday#startTime"
										name="friday#startTimes#4"
										value={(channelConfigInstances ?? [])[i].startTimes[4]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[4]}
										hasError={validationErrorsStartTime[4].length > 0}
									/>
									{validationErrorsStartTime[4].length > 0 && (
										<HelpText variant="error">
											{validationErrorsStartTime[4]}
										</HelpText>
									)}
								</Td>
								<Td>
									<TimePicker
										id="friday#endTime"
										name="friday#endTimes#4"
										value={(channelConfigInstances ?? [])[i].endTimes[4]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[4]}
										hasError={validationErrorsEndTime[4].length > 0}
									/>
									{validationErrorsEndTime[4].length > 0 && (
										<HelpText variant="error">
											{validationErrorsEndTime[4]}
										</HelpText>
									)}
								</Td>
								<Td>
									<Checkbox
										key="friday#override#4"
										id="friday#override#4"
										checked={
											(channelConfigInstances ?? [])[i].timeOverride[4]
										}
										value="friday#override#4"
										name="friday#override#4"
										aria-label="Friday 24 hours"
										onClick={(e) => handleEnableDisable24hours(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Checkbox
										key="friday#offline#4"
										id="friday#offline#4"
										checked={(channelConfigInstances ?? [])[i].offline[4]}
										value="friday#offline#4"
										name="friday#offline#4"
										aria-label="Friday offline"
										onClick={(e) => handleEnableDisableOffline(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Input
										type="number"
										id="friday#connectionLimits#4"
										name="friday#connectionLimits#4"
										value={
											(channelConfigInstances ?? [])[i].connectionLimits[4]
										}
										onChange={handleChange}
										disabled={(channelConfigInstances ?? [])[i].offline[4]}
										hasError={validationErrorsLimit[4].length > 0}
										aria-label="Friday daily connection limit"
									/>
									{validationErrorsLimit[4].length > 0 && (
										<HelpText variant="error">
											{validationErrorsLimit[4]}
										</HelpText>
									)}
								</Td>
							</Tr>
							<Tr>
								<Td>Saturday</Td>
								<Td>
									<TimePicker
										id="saturday#startTime"
										name="saturday#startTimes#5"
										value={(channelConfigInstances ?? [])[i].startTimes[5]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[5]}
										hasError={validationErrorsStartTime[5].length > 0}
									/>
									{validationErrorsStartTime[5].length > 0 && (
										<HelpText variant="error">
											{validationErrorsStartTime[5]}
										</HelpText>
									)}
								</Td>
								<Td>
									<TimePicker
										id="saturday#endTime"
										name="saturday#endTimes#5"
										value={(channelConfigInstances ?? [])[i].endTimes[5]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[5]}
										hasError={validationErrorsEndTime[5].length > 0}
									/>
									{validationErrorsEndTime[5].length > 0 && (
										<HelpText variant="error">
											{validationErrorsEndTime[5]}
										</HelpText>
									)}
								</Td>
								<Td>
									<Checkbox
										key="saturday#override#5"
										id="saturday#override#5"
										checked={
											(channelConfigInstances ?? [])[i].timeOverride[5]
										}
										value="saturday#override#5"
										name="saturday#override#5"
										aria-label="Saturday 24 hours"
										onClick={(e) => handleEnableDisable24hours(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Checkbox
										key="saturday#offline#5"
										id="saturday#offline#5"
										checked={(channelConfigInstances ?? [])[i].offline[5]}
										value="saturday#offline#5"
										name="saturday#offline#5"
										aria-label="Saturday offline"
										onClick={(e) => handleEnableDisableOffline(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Input
										type="number"
										id="saturday#connectionLimits#5"
										name="saturday#connectionLimits#5"
										value={
											(channelConfigInstances ?? [])[i].connectionLimits[5]
										}
										onChange={handleChange}
										disabled={(channelConfigInstances ?? [])[i].offline[5]}
										hasError={validationErrorsLimit[5].length > 0}
										aria-label="Saturday daily connection limit"
									/>
									{validationErrorsLimit[5].length > 0 && (
										<HelpText variant="error">
											{validationErrorsLimit[5]}
										</HelpText>
									)}
								</Td>
							</Tr>
							<Tr>
								<Td>Sunday</Td>
								<Td>
									<TimePicker
										id="sunday#startTime"
										name="sunday#startTimes#6"
										value={(channelConfigInstances ?? [])[i].startTimes[6]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[6]}
										hasError={validationErrorsStartTime[6].length > 0}
									/>
									{validationErrorsStartTime[6].length > 0 && (
										<HelpText variant="error">
											{validationErrorsStartTime[6]}
										</HelpText>
									)}
								</Td>
								<Td>
									<TimePicker
										id="sunday#endTime"
										name="sunday#endTimes#6"
										value={(channelConfigInstances ?? [])[i].endTimes[6]}
										onChange={handleChange}
										enterKeyHint={undefined}
										disabled={(channelConfigInstances ?? [])[i].offline[6]}
										hasError={validationErrorsEndTime[6].length > 0}
									/>
									{validationErrorsEndTime[6].length > 0 && (
										<HelpText variant="error">
											{validationErrorsEndTime[6]}
										</HelpText>
									)}
								</Td>
								<Td>
									<Checkbox
										key="sunday#override#6"
										id="sunday#override#6"
										checked={
											(channelConfigInstances ?? [])[i].timeOverride[6]
										}
										value="sunday#override#6"
										name="sunday#override#6"
										aria-label="Sunday 24 hours"
										onClick={(e) => handleEnableDisable24hours(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Checkbox
										key="sunday#offline#6"
										id="sunday#offline#6"
										checked={(channelConfigInstances ?? [])[i].offline[6]}
										value="sunday#offline#6"
										name="sunday#offline#6"
										aria-label="Sunday offline"
										onClick={(e) => handleEnableDisableOffline(e)}
									>
										{null!}
									</Checkbox>
								</Td>
								<Td>
									<Input
										type="number"
										id="sunday#connectionLimits#6"
										name="sunday#connectionLimits#6"
										value={
											(channelConfigInstances ?? [])[i].connectionLimits[6]
										}
										onChange={handleChange}
										disabled={(channelConfigInstances ?? [])[i].offline[6]}
										hasError={validationErrorsLimit[6].length > 0}
										aria-label="Sunday daily connection limit"
									/>
									{validationErrorsLimit[6].length > 0 && (
										<HelpText variant="error">
											{validationErrorsLimit[6]}
										</HelpText>
									)}
								</Td>
							</Tr>
						</TBody>
					</Table>
				</Box>
			)}

			{loaded &&
        channelConfigInstances &&
        channelConfigInstances[i].connectionType === "Phone" && (
				<Box paddingTop="space100">
					<Box paddingY="space60">
						<Heading as="h3" variant="heading30">
                Step 3
						</Heading>

						<Text as="p" lineHeight="lineHeight40">
                Where should we forward connections?
						</Text>
					</Box>

					<Box width="20%">
						<Label htmlFor="destination">Phone #</Label>

						<Input
							onChange={handleTextChange}
							key="destination"
							aria-describedby="phone_help_text"
							id="destination"
							name="destination"
							value={channelConfigInstances[i].destination}
							type="tel"
							required
							hasError={phoneError.length > 0}
						/>
						{phoneError.length > 0 && (
							<HelpText variant="error">{phoneError}</HelpText>
						)}
						<HelpText variant="error" id="phone_help_text">
                * Must be toll-free number
						</HelpText>
					</Box>
				</Box>
			)}

			{loaded &&
        channelConfigInstances &&
        channelConfigInstances[i].connectionType === "Online" && (
				<Box marginY="space60" display="flex" flexDirection="column">
					<Box marginY="space60">
						<Heading as="h3" variant="heading30">
                Step 3
						</Heading>
					</Box>

					<Box width="40%">
						<Label required htmlFor="destinationEmail">
                What email should we send client contact information to?
						</Label>
						<Input
							required
							onChange={handleTextChange}
							id="destination"
							name="destinationEmail"
							type="text"
							value={channelConfigInstances[i].destinationEmail}
							hasError={emailError.length > 0}
						/>
						{emailError.length > 0 && (
							<HelpText variant="error">{emailError}</HelpText>
						)}
					</Box>

					<Box
						width="40%"
						paddingY="space100"
						rowGap="space40"
						display="flex"
						flexDirection="column"
					>
						<Label htmlFor="intake">
                Do you want consumers to continue to your online intake form?
						</Label>
						<Text
							fontSize="fontSize20"
							as="p"
							color="colorTextLinkDestructive"
							id="online-intake"
						>
                * Agency must have dedicated landing page set up.
						</Text>
						<CheckboxGroup
							orientation="horizontal"
							id="intake"
							name="intake"
							legend=""
						>
							<Checkbox
								id="chk"
								checked={intakeUrlVisible}
								onChange={(e) => {
									setIntakeUrlVisibility(e);
								}}
							>
                  Yes
							</Checkbox>
						</CheckboxGroup>
						{intakeUrlVisible && (
							<>
								<Label required htmlFor="intake">
                    Intake form URL
								</Label>{" "}
								<Input
									onChange={handleTextChange}
									aria-label="Intake form URL"
									id="destinationIntakeUrl"
									name="destinationIntakeUrl"
									type="text"
									value={channelConfigInstances[i].destinationIntakeUrl}
								/>
							</>
						)}
						{destinationUrlError.length > 0 && (
											<HelpText variant="error">{destinationUrlError}</HelpText>
										)}
					</Box>
				</Box>
			)}

			{loaded &&
        channelConfigInstances &&
        channelConfigInstances[i].connectionType === "Online" && (
				<>
					<Box marginY="space60" display="flex" flexDirection="column">
						<Box paddingY="space60">
							<Heading as="h3" variant="heading30">
                  Step 4
							</Heading>
						</Box>

						<Box width="40%">
							<Label required htmlFor="introduction">
                  How do you want to introduce your agency to the client?
							</Label>

							<TextArea
								required
								id="introduction"
								name="commsIntroduction"
								value={channelConfigInstances[i].commsIntroduction}
								defaultValue={channelConfigInstances[i].commsIntroduction}
								onChange={(e) => {
									handleTextChange(e);
								}}
								hasError={agencyIntroError.length > 0}
							/>
							{agencyIntroError.length > 0 && (
								<HelpText variant="error">{agencyIntroError}</HelpText>
							)}

							<Text
								marginY="space60"
								as="p"
								color="colorTextInverseWeaker"
								lineHeight="lineHeight40"
							>
                  Add text here to introduce your agency to the client
							</Text>

							<Text
								fontSize="fontSize20"
								as="p"
								color="colorTextLinkDestructive"
							>
                  * Limit 300 characters
							</Text>
						</Box>

						<Box
							marginY="space60"
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
						>
							<Box display="flex" flexDirection="column" width="40%">
								<Label htmlFor="url">
                    What is the URL you would like us to include?
								</Label>
								<Input
									required
									id="url"
									name="commsUrl"
									type="text"
									value={channelConfigInstances[i].commsUrl}
									onChange={handleTextChange}
								/>
							</Box>
						</Box>
						<Box width="40%">
							<Label htmlFor="destination">
                  What phone number should we include?
							</Label>
							<Input
								onChange={handleTextChange}
								aria-label="Phone number input field"
								id="commsPhone"
								name="commsPhone"
								type="tel"
								value={channelConfigInstances[i].commsPhone}
							/>
						</Box>
						<Box
							marginY="space60"
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
						>
							<Box display="flex" flexDirection="column" width="40%">
								<Label required htmlFor="image-url">
                    Add a link to your logo, in high resolution
								</Label>
								<Input
									aria-label="URL link to logo image"
									id="image-url"
									name="commsLogoUrl"
									type="text"
									value={channelConfigInstances[i].commsLogoUrl}
									onChange={handleTextChange}
									hasError={logoError.length > 0}
								/>
								{logoError.length > 0 && (
									<HelpText variant="error">{logoError}</HelpText>
								)}
							</Box>

							<Box display="flex" flexDirection="column">
								<Text
									marginY="space60"
									as="p"
									color="colorTextInverseWeaker"
									lineHeight="lineHeight40"
								>
                    Include an image in your emails
								</Text>

								<Button variant="secondary" onClick={handleOpen}>
                    Preview Email
									<ShowIcon decorative={false} title="Preview Email Icon" />
								</Button>
							</Box>
						</Box>
					</Box>

					<Modal
						isOpen={isOpenModal}
						onDismiss={handleClose}
						size="wide"
						ariaLabelledby={modalHeadingID}
					>
						<ModalHeader>
							<ModalHeading as="h2" id={modalHeadingID}>
                  Email Preview
							</ModalHeading>
						</ModalHeader>

						<ModalBody>
							<div
								dangerouslySetInnerHTML={{
									__html: getTemplate(channelConfigInstances[i].language)
								}}
							/>
						</ModalBody>

						<ModalFooter>
							<ModalFooterActions>
								<Button variant="primary" onClick={handleClose}>
                    Close Preview
								</Button>
							</ModalFooterActions>
						</ModalFooter>
					</Modal>
				</>
			)}

			<Box
				marginY="space60"
				display="flex"
				justifyContent="space-between"
				position="relative"
				bottom="0"
			>
				{i === 0 && (
					<Button variant="secondary">
						<Link
							style={{ color: "rgb(18, 28, 45)", textDecoration: "none" }}
							to={getPreviousLink()}
							state={{
								channelConfigs: channelConfigs,
								selectedServiceIds: selectedServiceIds,
								channelConfigInstances: channelConfigInstances
							}}
						>
              Back
						</Link>
					</Button>
				)}
				{i > 0 && (
					<Button variant="secondary" onClick={decrement}>
            Back
					</Button>
				)}
				{channelConfigInstances && i === channelConfigInstances.length - 1 && (
					<Button variant="primary" onClick={(e) => handleNextOpen()}>
            Continue
					</Button>
				)}
				{channelConfigInstances && i < channelConfigInstances.length - 1 && (
					<Button variant="primary" onClick={(e) => increment()}>
            Next
					</Button>
				)}
			</Box>

			<Modal
				isOpen={isOpenNextModal}
				onDismiss={handleNextClose}
				size="wide"
				ariaLabelledby="reviewmodal"
				aria-label="Review modal"
			>
				<ModalHeader>
					<ModalHeading as="h2" id="reviewmodal">
            Continue to Review
					</ModalHeading>
				</ModalHeader>

				<ModalBody>
					<Text aria-label="Review completion text" as="p" lineHeight="lineHeight40">
            You have completed service setup. You can review all settings on the
            next page.
					</Text>
				</ModalBody>

				<ModalFooter>
					<ModalFooterActions>
						<Button variant="primary">
							<Link
								style={{ color: "white", textDecoration: "none" }}
								to={getNextLink()}
								state={{
									channelConfigInstances: channelConfigInstances,
									selectedServiceIds: selectedServiceIds,
									channelConfigs: channelConfigs
								}}
							>
                Next
							</Link>
						</Button>
					</ModalFooterActions>
				</ModalFooter>
			</Modal>
		</>
	);
};
