import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { PhoneNumbers, Services, PhoneNumber } from "@ciptex/nfcc";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Button, AlertDialog, Flex } from "@twilio-paste/core";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { VoiceCapableIcon } from "@twilio-paste/icons/esm/VoiceCapableIcon";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { DateTime } from "luxon";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { HelpText } from "@twilio-paste/core/help-text";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { Label } from "@twilio-paste/core/label";
import { Select, Option } from "@twilio-paste/core/select";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { Stack } from "@twilio-paste/core/stack"

export const PhoneNumbersTable: FC = () => {
	const [phoneNumbers, setPhoneNumbers] = useState<any[]>();
	const [filteredPhoneNumbers, setFilteredPhoneNumbers] = useState<any[]>();
	const [services, setServices] = useState<Services>();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [serviceFilterValue, setServiceFilterValue] = useState<string>("All Services");
	const [languageFilterValue, setLanguageFilterValue] = useState<string>("All Languages");

	const { listPhoneNumbers, deletePhoneNumber, listServices, provisionPhoneNumber } = useNFCCContext();
	const { appState } = useAppState();
	const { toaster } = useToasterContext();

	// confirmation modal state management
	const [toDelete, setToDelete] = useState<any>();
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = (phoneNumberId: number, rowIndex: number) => {
		setToDelete({ phoneNumberId, rowIndex });
		setIsOpen(true);
	};
	const handleClose = () => {
		setIsOpen(false);
	};

	const navigate = useNavigate();

	const getDate = () => {
		const today = DateTime.local();
		const todayDate = today.toFormat("dd-MM-yyyy-HH-mm-ss");
		return todayDate;
	};

	const exportTableData = () => {
		if (phoneNumbers) {
			const csvData = [
				[
					"Number",
					"Languages Allocated",
					"Services Allocated",
					"Campaign",
					"Description",
					"Is Toll-Free",
					"Error Handling Type"
				],
				...phoneNumbers.map(
					({
						number,
						langString,
						servString,
						campaignName,
						description,
						isTollFree,
						errorHandlingType
					}) => [
						number,
						langString.replaceAll(",", ";"),
						servString.replaceAll(",", ";"),
						campaignName,
						description,
						isTollFree,
						errorHandlingType
					]
				)
			];
			const csv = csvData.map((row) => row.join(",")).join("\n");
			const csvBlob = new Blob([csv], { type: "text/csv" });
			const csvUrl = URL.createObjectURL(csvBlob);
			const downloadLink = document.createElement("a");
			downloadLink.href = csvUrl;
			downloadLink.download = `phoneNumbers-${getDate()}.csv`;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const data: PhoneNumbers = await listPhoneNumbers();
				if (data) {
					const newPns = [];
					for (const d of data) {
						let l = "";
						let s = "";

						for (const la of d.languages ?? []) {
							l += la + ", ";
						}

						for (const la of d.services ?? []) {
							s += la.name + ", ";
						}

						l = l.slice(0, -2);
						s = s.slice(0, -2);

						newPns.push({ ...d, ["langString"]: l, ["servString"]: s });
					}

					setPhoneNumbers(newPns);
					setFilteredPhoneNumbers(newPns)

					const s = await listServices();
					setServices(s);

					setLoaded(true);
				}
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve phone number data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	const handleDelete = async (phoneNumberId: number, rowIndex: number) => {
		if (phoneNumbers) {
			try {
				await deletePhoneNumber(phoneNumberId);
				toaster.push({
					message: "Phone number deleted successfully",
					variant: "success",
					dismissAfter: 4000
				}); // should we then deprovision the phone number? Check with NFCC TODO CAZ

				setPhoneNumbers(
					phoneNumbers.filter((pn) => pn.phoneNumberId !== phoneNumberId)
				);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Phone number could not be deleted",
					variant: "error",
					dismissAfter: 4000
				});
			}
		}
	};

	const provision = async (pnType: string) => {
		if(phoneNumbers)
		{
			try {
				const pn : PhoneNumber = await provisionPhoneNumber(pnType);
				toaster.push({
					message: "Phone number provisioned successfully",
					variant: "success",
					dismissAfter: 4000
				}); // should we then deprovision the phone number? Check with NFCC TODO CAZ

				const pns = (phoneNumbers?? []).map((x) => x);
				pns.push(pn)
				setPhoneNumbers(
					pns
				);
				setFilteredPhoneNumbers(pns)
				setServiceFilterValue("All Services")
				setLanguageFilterValue("All Languages")
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Phone number could not be provisioned",
					variant: "error",
					dismissAfter: 4000
				});
			}

		}

	};

	const handleCloseWithDelete = () => {
		setIsOpen(false);
		handleDelete(toDelete.phoneNumberId, toDelete.rowIndex);
		setToDelete({});
	};



	const handleFilterChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if(target.name === "service")
		{
			setServiceFilterValue(target.value)
		}
		else if (target.name === "language")
		{
			setLanguageFilterValue(target.value)
		}

	};


	const applyFilters = () => {
		if (phoneNumbers) {

			let filteredPn : any[] = phoneNumbers;

			if (serviceFilterValue !== "All Services") {
				filteredPn = filteredPn.filter(
					(a) => (a.servString??"").includes(serviceFilterValue)
				)
			}

			if (languageFilterValue !== "All Languages") {
				filteredPn = filteredPn.filter(
					(a) => (a.langString??"").includes(languageFilterValue)
				)
			}

			setFilteredPhoneNumbers(filteredPn)

		}

	};

	return (
		<Box width="100%">
			<HeaderTitleText titleText="Phone Numbers" />
			<Box display="flex" justifyContent="flex-end" marginY="space60">
				<Flex>
					<Box display="flex" justifyContent="space-between" marginY="space60" alignItems="flex-end" columnGap="space60">
						<Button variant="primary" onClick={e => provision("toll-free")}>
							Provision Toll-Free Number
							<VoiceCapableIcon decorative={false} title="Provision phone number" />
						</Button>
						<Button variant="primary" onClick={e => provision("local")}>
							Provision Local Number
							<VoiceCapableIcon decorative={false} title="Provision local number" />
						</Button>
						<Button variant="secondary" onClick={exportTableData}>
							Download CSV
							<DownloadIcon decorative={false} title="Download csv icon" />
						</Button>
					</Box>
				</Flex>
			</Box>

			<Box display="flex" flexDirection="row" marginY="space60" justifyContent="space-between" alignItems="flex-end">

				<Box display="flex" flexDirection="row" columnGap="space60">



					<Box display="flex" flexDirection="column" width="15vw" alignItems="flex-start">
						<Label htmlFor="service">Service</Label>
						<Select id="service" name="service" onChange={handleFilterChange} value = {serviceFilterValue}>
							<Option value="All Services">All Services</Option>
							{services && loaded ? (

								services && services.map((s: any, index: number) => (

									<Option key={index} value={s.name} >{s.name}</Option>

								))) : null!}
						</Select>
					</Box>

					<Box display="flex" flexDirection="column" width="15vw" alignItems="flex-start">
						<Label htmlFor="language">Language</Label>
						<Select id="language" name="language" onChange={handleFilterChange} value={languageFilterValue}>
							<Option value="All Languages">All Languages</Option>
							<Option value="English">English</Option>
							<Option value="Spanish">Spanish</Option>
						</Select>
					</Box>

				</Box>

				<Box display="flex" justifyContent="flex-end" height="fit-content">
					<Button variant="primary" onClick={applyFilters}>
						<FilterIcon decorative={false} title="Apply filters" />
							Apply
					</Button>
				</Box>

			</Box>

			<Table aria-label="Phone Numbers table" striped>
				<THead>
					<Tr>
						<Th>Actions</Th>
						<Th>Number</Th>
						<Th>Campaign</Th>
						<Th>Description</Th>
						{/* <Th>Nearest Branch Location Routing</Th> */}
						<Th>Skips IVR</Th>
						<Th>Error Handling Type</Th>
						<Th>Services</Th>
						<Th>Languages</Th>
					</Tr>
				</THead>
				<TBody>
					{filteredPhoneNumbers && loaded ? (
						filteredPhoneNumbers &&
						filteredPhoneNumbers.map((pn: any, rowIndex: number) => (
							<Tr key={rowIndex}>
								<Td>
									<Box
										display="flex"
										justifyContent="flex-start"
										flexDirection="row"
										columnGap="space60"
									>
										<Stack orientation="vertical" spacing="space60">
											<Button
												variant="secondary"
												onClick={() =>
													navigate(`/phone-numbers/edit/${pn.phoneNumberId}`)
												}
											>
												<EditIcon decorative={false} title="Edit Phone Number" />
											</Button>
											<Button
												variant="destructive"
												onClick={() => handleOpen(pn.phoneNumberId, rowIndex)}
											>
												<DeleteIcon
													decorative={false}
													title="Delete Phone Number"
												/>
											</Button>
										</Stack>
									</Box>
								</Td>
								<Td>{pn.number}</Td>
								<Td>{pn.campaignName}</Td>
								<Td>{pn.description}</Td>
								{/* <Td>
									{(pn.geolocationEnabled === 1 && "Yes") ||
										(pn.geolocationEnabled === 0 && "No")}
								</Td> */}
								<Td>
									{(pn.isTollFree === 1 && "No") ||
										(pn.isTollFree === 0 && "Yes")}
								</Td>
								<Td>{pn.errorHandlingType.charAt(0).toUpperCase() + pn.errorHandlingType.slice(1)}</Td>
								<Td>{pn.servString}</Td>
								<Td>{pn.langString}</Td>

							</Tr>
						))
					) : (
						<TableSkeletonLoader numberOfTd={8} numberOfTr={1} />
					)}
				</TBody>
			</Table>
			<AlertDialog
				heading="Deactivate Phone Number"
				isOpen={isOpen}
				destructive
				onConfirm={handleCloseWithDelete}
				onConfirmLabel="Delete"
				onDismiss={handleClose}
				onDismissLabel="Cancel"
			>
				Please confirm you want to delete this phone number.
				<HelpText variant="error">
					Warning - this step will not deprovision this phone number from your
					Twilio account. To do this, please contact Ciptex support or access
					the Twilio console.
				</HelpText>
			</AlertDialog>
		</Box>
	);
};
