// General
import NFCCLogo from "./images/nfcc-logo.png";
import NFCCMenuLogo from "./images/nfcc_menu_logo.png";
import AgencyHome from "./images/agency_home.svg";
export const LOGO = NFCCLogo;
export const MENU_LOGO = NFCCMenuLogo;
export const HEADERS_HEIGHT = "64px";
export const LOGO_HEIGHT = "46px";
export const ACCOUNT_SID = process.env.REACT_APP_ACCOUNT_SID || "";
export const TOKEN = process.env.REACT_APP_TOKEN || "";

// Color Section
export const FORM_BACKGROUND = "rgb(255, 255, 255)";
export const SUCCESS = "#64CCC9";
export const UNAVAILABLE = "#003939";
export const RED = "#BD0060";
export const WEAKEST = "#e8f3f1";


export const Role = {
	admin: "nfcc_admin",
	client: "nfcc_agency"
}
