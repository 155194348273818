import { FC, useEffect, useState } from "react";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Agency } from "@ciptex/nfcc";
import { Box } from "@twilio-paste/core/box";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useParams } from "react-router-dom";
import { SubtitleText } from "../HeaderTitleText/SubtitleText";

export const AgencyHome: FC = () => {
	const { agencyId } = useParams();
	const [agency, setAgency] = useState<Agency>();
	const [, setLoaded] = useState<boolean>(false);

	const { getAgency } = useNFCCContext();
	const { appState } = useAppState();
	const { toaster } = useToasterContext();

	useEffect(() => {
		(async () => {
			try {
				if(agencyId)
				{
					const data: Agency = await getAgency(parseInt(agencyId ? agencyId : ""));
					setAgency(data);
					setLoaded(true);
				}
				else
				{
					const data: Agency = await getAgency(appState.agencyId);
					setAgency(data);
					setLoaded(true);
				}

			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve agency data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);



	return (
		<Box width="100%" display="flex" alignItems="flex-start" flexDirection="column">

			{agency &&
				<SubtitleText subtitleText={"Hello " + agency.name+"!"}></SubtitleText>}

		</Box>
	);
};
