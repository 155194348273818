import { produce } from "immer";

export interface appStateTypes {
	token: string;
	role: string;
	agencyId: number;
	agencyName: string;
	email: string
}

export type appActionTypes =
	| { type: "set-token"; token: string }
	| { type: "set-role"; role: string }
	| { type: "set-agencyId"; agencyId: number }
	| { type: "set-agencyName"; agencyName: string }
	| { type: "set-email"; email: string }
	| { type: "set-is-loading"; isLoading: boolean }
	| { type: "reset-state" }

export const initialAppState: appStateTypes = {
	token: "",
	role: "",
	agencyId: 0,
	agencyName: "",
	email: ""
};

export const appReducer = produce((draft: appStateTypes, action: appActionTypes) => {
	switch (action.type) {
	case "set-token":
		draft.token = action.token;
		break;
	case "set-role":
		draft.role = action.role;
		break;
	case "set-agencyId":
		draft.agencyId = action.agencyId;
		break;
	case "set-agencyName":
		draft.agencyName = action.agencyName;
		break;
	case "set-email":
		draft.email = action.email;
		break;
	case "reset-state":
		return initialAppState;
	default:
		console.error("Invalid Reducer");
		break;
	}
});