import {  FC, useEffect } from "react";
import { Box } from "@twilio-paste/core/box";
import { Role } from "../../constants";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "@twilio-paste/core/spinner";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";

export const ManageServicesRedirect: FC = () => {

	const navigate = useNavigate();
	const { appState } = useAppState();
	const { agencyId } = useParams();
	const pathname = "/agencies/"+agencyId

	useEffect(() => {
		(async () => {
			if(appState.role === Role.admin)
			{
				setTimeout(() => {
					navigate(pathname+"/setup/success")
				}, 3000);

			}
			else
			{
				setTimeout(() => {
					navigate("/setup/success")
				}, 3000);

			}
		})();
	}, []);

	return (
		<Box width="100%" display="flex" flexDirection="column" marginTop="space200" alignItems="center">
			<HeaderTitleText titleText="Setting up your service configurations, please wait..."/>
			<Box display="flex" marginTop="space200">
				<Spinner decorative={false} title="Loading" size="sizeIcon100" />
			</Box>
		</Box>
	);
};
